import { observable, autorun, computed, action } from 'mobx';
import RootStore from './RootStore';
import { QuestDocumentApi, QuestDocument, QuestTocNode, parseQuestId, QuestDataOptions } from '../api/quest';

export default class DocumentStore {
  private _questDocumentApi: QuestDocumentApi;

  constructor(private _rootStore: RootStore) {
    this._questDocumentApi = new QuestDocumentApi();

    autorun(() => {
      this._loadInfo();
    });
  }

  @observable
  document = new QuestDocument();

  @observable
  compareVersion?= 0;

  @observable
  tocNodes = new Array<QuestTocNode>();

  @observable
  htmlData = "";

  @observable
  rootQuestId = "";

  @observable
  shareOpen = false;

  @computed
  get isReady() {
    return this.document.questId !== "";
  }

  @action
  setShareOpen(value: boolean) {
    this.shareOpen = value;
  }

  @action
  setCompareVersion(value?: number) {
    this.compareVersion = value;
    this._loadHtmlData();
  }

  @action
  toggleTocNode = async (node: QuestTocNode) => {
    if (node.opened) {
      node.opened = false;
    } else {
      node.opened = true;

      if (node.childCount > 0 && !node.childs) {
        const list = await this._questDocumentApi.getLevel(this._rootStore.userStore.questContext, this.document.version, node.nodeId);

        node.childs = list;
      }
    }
  }

  private _loadInfo = async () => {
    if (this._rootStore.userStore.isReady && this._rootStore.viewStore.questId) {
      const info = await this._questDocumentApi.getInfo(
        this._rootStore.userStore.questContext,
        this._rootStore.viewStore.questId, {
          includeLicenseItem: true
        });

      this.document = info;

      const rootquestid = parseQuestId(this.document.questId).add(this.document.version.version).toString(true);

      if (this.rootQuestId !== rootquestid) {
        await this._loadRootToc();
        this.rootQuestId = rootquestid;
        this.compareVersion = undefined;
      }

      await this._loadHtmlData();
    }
  }

  private _loadRootToc = async () => {
    if (this._rootStore.userStore.isReady && this.document.questId) {
      const list = await this._questDocumentApi.getLevel(this._rootStore.userStore.questContext, this.document.version);

      this.tocNodes = list;
    }
  }

  private _loadHtmlData = async () => {
    if (this._rootStore.userStore.isReady && this._rootStore.viewStore.questId) {
      this.htmlData = "";

      if (this.document.license && this.document.version.node.mimeType === "application/xml") {
        const questid = this.tocNodes.length === 0 ?
          this.document.version.questId : this.document.version.node.questId;

        if (questid !== "") {
          if (this.compareVersion && this.compareVersion > 0) {
            this.htmlData = await this._questDocumentApi.getCompareDataHtml(this._rootStore.userStore.questContext,
              questid, this.compareVersion, this._getDataOptions(this.document));
          } else {
            this.htmlData = await this._questDocumentApi.getDataHtml(this._rootStore.userStore.questContext,
              questid, this._getDataOptions(this.document));
          }
        }
      }
    }
  }

  private _getDataOptions(doc: QuestDocument): QuestDataOptions {
    let transform = "";

    if (doc.version.importerId === "QuestIni") {
      if (doc.metadata.getFirst("Custom", "QuestImporter") === "Osf") {
        transform = "komo-osf";
      } else {
        transform = "bw5-brispub";
      }
    } else {
      transform = "bw5-bouwbesluit";
    }

    return {
      transform: transform,
      linkManager: true
    }
  }
}