import { QuestDocument } from './types-document';

export class QuestMetadata {
  data: any = {};
  referenceData: any = {};

  get(family: string, label: string): Array<string> {
    const f = this.data[family];
    if (f) {
      const values = f[label];
      if (values) {
        return values;
      }
    }

    return new Array<string>();
  }

  getReference(family: string, label: string): Array<QuestDocument> {
    const f = this.referenceData[family];
    if (f) {
      const values = f[label];
      if (values) {
        return values;
      }
    }

    return new Array<QuestDocument>();
  }

  getFirst(family: string, label: string, defaultValue: string | null = null): string | null {
    const values = this.get(family, label);

    if (values.length > 0) {
      return values[0];
    } else {
      return defaultValue;
    }
  }

  getFirstReference(family: string, label: string): QuestDocument | null {
    const values = this.getReference(family, label);

    if (values.length > 0) {
      return values[0];
    } else {
      return null;
    }
  }

  fromServer(data: any) {
    this._addList(data.MetadataList);
    if (data.Document && data.Document.MetadataList) {
      this._addList(data.Document.MetadataList);
    }
  }

  private _addList(metadataList: any) {
    if (metadataList) {
      metadataList.forEach((item: any) => {
        this.data[item.Family] = this.data[item.Family] || {};

        if (this.data[item.Family][item.Label]) {
          this.data[item.Family][item.Label].push(item.Value);
        } else {
          this.data[item.Family][item.Label] = [item.Value];          
        }

        if (item.DataTypeHint === "QuestId" && item.QuestReference) {
          this.referenceData[item.Family] = this.referenceData[item.Family] || {};

          var d = new QuestDocument();
          d.fromServer(item.QuestReference);

          if (this.referenceData[item.Family][item.Label]) {
            this.referenceData[item.Family][item.Label].push(d);
          } else {
            this.referenceData[item.Family][item.Label] = [d];
          }
        }
      });
    }
  }
}
