import axios from 'axios';
import { QuestContext } from './types';
import { QuestDocument, QuestInfoOptions, QuestTocNode, QuestDataOptions, QuestDocumentVersion } from './types-document';
import { QuestVersionMapOptions, QuestVersionMap } from './types-versionmap';
import { toQueryString, getRequestOptions } from './utils';
import { parseQuestId } from './identification';

export class QuestDocumentApi {
  private _API: string = "/api/document/";

  async getInfo(context: QuestContext, questId: string, options?: QuestInfoOptions): Promise<QuestDocument> {
    const o = new QuestInfoOptions();
    if (options) {
      Object.assign(o, options);
    }

    return await axios.get(`${context.endpoint}${this._API}info/${questId}?${toQueryString(o)}`, getRequestOptions(context)).then((response) => {
      const d = new QuestDocument();

      if (response.status === 200) {
        d.fromServer(response.data);
      }

      return d;
    });
  }

  async getDataHtml(context: QuestContext, questId: string, options?: QuestDataOptions): Promise<string> {
    const o = new QuestDataOptions();
    if (options) {
      Object.assign(o, options);
    }

    return await axios.get(`${context.endpoint}${this._API}data/${questId}?${toQueryString(o)}`,
      getRequestOptions(context, "text/html")).then((response) => {
        let html = "";

        if (response.status === 200) {
          html = response.data;
        }

        return html;
      });
  }

  async getCompareDataHtml(context: QuestContext, questId: string, compareVersion: number, options?: QuestDataOptions): Promise<string> {
    const o = new QuestDataOptions();
    if (options) {
      Object.assign(o, options);
    }

    const comparequestid = parseQuestId(questId).add(compareVersion).toString(true);

    return await axios.get(`${context.endpoint}${this._API}compare/${questId}?${toQueryString(o)}&id2=${comparequestid}`,
      getRequestOptions(context, "text/html")).then((response) => {
        let html = "";

        if (response.status === 200) {
          html = response.data;
        }

        return html;
      });
  }

  async getLevel(context: QuestContext, questDocumentVersion: QuestDocumentVersion, questNodeId?: string): Promise<Array<QuestTocNode>> {
    const o = {
      questId: questDocumentVersion.questId
    };

    return await axios.get(`${context.endpoint}${this._API}toclevel/${questNodeId ? questNodeId : ""}?${toQueryString(o)}`, getRequestOptions(context)).then((response) => {
      const list = new Array<QuestTocNode>();

      if (response.status === 200) {
        response.data.forEach((item: any) => {
          list.push({
            questId: parseQuestId(questDocumentVersion.document.questId).add(item.QuestId).add(questDocumentVersion.version).toString(true),
            nodeId: item.QuestId,
            title: item.Title,
            childCount: item.ChildCount,
            composable: item.Composable,
            hasChilds: item.HasChilds,
            mimeType: item.MimeType,
            opened: false
          });
        });
      }

      return list;
    });
  }

  async getVersionMap(context: QuestContext, questId: string, options?: QuestVersionMapOptions): Promise<QuestVersionMap> {
    const o = new QuestVersionMapOptions();
    if (options) {
      Object.assign(o, options);
    }

    return await axios.get(`${context.endpoint}${this._API}versionmap/${questId}?${toQueryString(o)}`,
      getRequestOptions(context)).then((response) => {
        const vm = new QuestVersionMap();
        vm.questId = questId;

        if (response.status === 200) {
          vm.fromServer(response.data);
        }

        return vm;
      });
  }
}