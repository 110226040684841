import { pickForTarget } from './utils';
import { sortFacetHits, filterFacetHits, facetNameForValue, filterFacets } from './utils-facet';

export class QuestFacetList {
  list = new Array<QuestFacet>();

  definitionList?: Array<QuestFacetDefinition>;

  getFacet(term: string): QuestFacet | undefined {
    return this.list.find((f: QuestFacet) => {
      return f.term === term;
    });
  }

  getHit(term: string, value: string): QuestFacetHit | undefined {
    const facet = this.getFacet(term);

    if (facet) {
      return facet.hits.find((h: QuestFacetHit) => {
        return h.value === value;
      });
    }
  }

  hitSelected(term: string, value: string): boolean {
    const hit = this.getHit(term, value);

    if (hit) {
      return hit.selected;
    } else {
      return false;
    }
  }

  termFilters(): string {
    const f = filterFacets(this).filter((facet: QuestFacet) => {
      return facet.hits.some((hit: QuestFacetHit) => {
        return hit.selected;
      });
    }).map((facet: QuestFacet) => {
      return {
        term: facet.term,
        values: facet.hits.filter((hit: QuestFacetHit) => {
          return hit.selected;
        }).map((hit: QuestFacetHit) => {
          return hit.value;
        })
      }
    });

    const r = f.map((sf) => {
      const l = sf.values.map((v) => {
        return `+${sf.term}:"${v}"`;
      });

      return l.join(" ");
    });

    return r.join(" ");
  }

  fromServer(data: any) {
    data.Facet.forEach((facetData: any) => {
      let facetdefinition: QuestFacetDefinition | undefined;

      if (this.definitionList) {
        facetdefinition = this.definitionList.find((facetDefinition) => {
          return facetDefinition.term === facetData.Term;
        });
      }

      let facet = new QuestFacet();
      facet.fromServer(facetData, facetdefinition);
      this.list.push(facet);
    });

    this.list = filterFacets(this);
  }
}

export class QuestFacet {
  fromServer(data: any, facetDefinition?: QuestFacetDefinition) {
    pickForTarget(data, this, "hits");

    data.Hits.forEach((hitData: any) => {
      this.hits.push({
        facet: this,
        name: facetNameForValue(hitData.Value, facetDefinition),
        value: hitData.Value,
        count: hitData.Count,
        selected: hitData.Selected
      });
    });

    this.hits = filterFacetHits(this.hits, facetDefinition);
    this.hits = sortFacetHits(this.hits, facetDefinition);
  }

  name = "";

  term = "";

  showCount = 25;

  showAllAvailable = false;

  showAll = false;

  hits = new Array<QuestFacetHit>();
}

export interface QuestFacetHit {
  facet: QuestFacet;

  name: string;

  value: string;

  count: number;

  selected: boolean;
}

export enum QuestFacetSortOrder { Ascending, Descending };
export enum QuestFacetSortField { Name, Value, Count };

export interface QuestFacetDefinition {
  name: string;

  term: string;

  // Returns if facet should be shown.
  show?: (facetList: QuestFacetList) => boolean;

  // Compare function. Return -1 if a < b, 1 if a > b or 0 when a == b
  sort?: (a: QuestFacetHit, b: QuestFacetHit) => number;

  sortOrder?: QuestFacetSortOrder;

  sortField?: QuestFacetSortField;

  // Associative object with mapping values or function to translate name.
  rename?: any | ((value: string) => string);

  // Include only values in array or approved by function.
  include?: Array<string> | ((value: string) => boolean);
}