import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';

interface Props {
  rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class DocumentPdf extends Component<Props> {
  render() {
    return (
			<object style={{ width: "100%", height: "100%", minHeight: "800px" }} data={`${this.props.rootStore!.documentStore.document.version.node.url}?magmalicensetoken=${this.props.rootStore!.userStore.questContext.licenseToken}`} type="application/pdf">PDF</object>      
    )
  }
}