import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { PATH_DOCUMENT } from '../../constants';
import { KomoDocumentNodeHit } from '../../stores/DocumentSearchStore';
import './../search/ResultList.css';

interface Props {
  rootStore?: RootStore;
  result: KomoDocumentNodeHit;
  index: number;
}

@inject("rootStore")
@observer
export default class QueryResult extends Component<Props> {
  select = (e: React.MouseEvent) => {
    if (!e.ctrlKey) {
      this.props.rootStore!.viewStore.setQuestId(this.props.result.questId);

      e.preventDefault();
    }
  }

  render() {
    return (
			<div className={"result result-" + (this.props.index % 2 === 0 ? "even" : "odd")}>
        <a href={PATH_DOCUMENT + this.props.result.questId} onClick={this.select}>{this.props.result.questTitle}</a>
        <p dangerouslySetInnerHTML={this._createMarkup(this.props.result.contents)}></p>
      </div>
    )
  }

  private _createMarkup(html: string) {
    return { __html: html };
  }
}