import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import Query from './search/Query';
import Filters from './search/Filters';
import ResultList from './search/ResultList';
import RecentList from './recent/RecentList';
import RootStore from '../stores/RootStore';
import './Search.css';

interface Props {
	rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class Search extends Component<Props> {
	render() {
		return (
			<Fragment>
				<Row className="search-area">
					<Col xs={12} sm={12} md={12} lg={9} xl={9} className="nopadding">
						<Query />
						<Row>
							<Col xs={12} sm={12} md={4} lg={4} xl={4}>
								<Filters />
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<ResultList />
							</Col>
						</Row>
					</Col>
					<Col xs={12} sm={12} md={12} lg={3} xl={3} className="nopadding-right">
						<RecentList />
					</Col>
				</Row>
				<Row>
					<Col className="nopadding">
						<hr />
					</Col>
				</Row>
			</Fragment>
		);
	}
}