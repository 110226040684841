import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { CustomInput } from 'reactstrap';
import RootStore from '../../stores/RootStore';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class DocumentFilter extends Component<Props> {
  select = (e: React.MouseEvent) => {
    this.props.rootStore!.searchStore.setQueryDocuments(!this.props.rootStore!.searchStore.queryDocuments);    
  }

  render() {
    return (
			<div className="switch" >
        <CustomInput type="switch" label="Alleen documenten" id="queryDocumentsFilter" defaultChecked={this.props.rootStore!.searchStore.queryDocuments} onClick={this.select} />
				<br /><br />
			</div>
    )
  }
}