import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Facet from './Facet';
import RootStore from '../../stores/RootStore';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class FacetList extends Component<Props> {
  render() {
    return (
      <div className="facetlist">
        {this.props.rootStore!.searchStore.queryResult!.facet.list.map((facet, index) => facet.hits.length > 0 &&
          <Facet facet={facet} index={index} key={facet.term} />
        )}
      </div>
    )
  }
}