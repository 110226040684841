import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';

interface Props {
  rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class DocumentNoLicense extends Component<Props> {
  render() {
    const d = this.props.rootStore!.documentStore.document;
    
    return (
      <div>
        <h2>{d.versionTitle}</h2>
    
        <strong>Helaas, u heeft geen toegang tot dit document.</strong>
      </div>
    );
  }
}