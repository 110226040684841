import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import RootStore from '../stores/RootStore';

interface Props {
	rootStore?: RootStore,
	text: string;
	description: string;
	path: string;
	viewId: string;
	enabled: boolean;
}

@inject("rootStore")
@observer
export default class NavigateTo extends Component<Props> {
	select = (e: React.MouseEvent) => {
		if (!e.ctrlKey) {
			this.props.rootStore!.viewStore.setViewId(this.props.viewId);
			e.preventDefault();
		}
	}

	render() {
		if (this.props.enabled) {
			return <a className={"menu-item" + (this.props.rootStore!.viewStore.viewId === this.props.viewId ? " selected" : "")} href={this.props.viewId} onClick={this.select}>
				<span className="menu-item-caption">{this.props.text}</span>
				<span className="menu-item-description">{this.props.description}</span>
			</a>
		} else {
			return <span className="menu-item disabled">
				<span className="menu-item-caption">{this.props.text}</span>
				<span className="menu-item-description">{this.props.description}</span>
			</span>
		}
	}
}