import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from './../../stores/RootStore';
import DocumentCover from './DocumentCover';
import DocumentHtml from './DocumentHtml';
import DocumentPdf from './DocumentPdf';
import DocumentNoLicense from './DocumentNoLicense';
import { DUBLINCORETYPE_KWALITEITSVERKLARING, DUBLINCORETYPE_BESTEKSPECIFICATIE } from './../../constants';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class Content extends Component<Props>{
  render() {
    if (!this.props.rootStore!.documentStore.document.version.isSet) {
      return <div>Laden...</div>
    } else if (!this.props.rootStore!.documentStore.document.license) {
      return <DocumentNoLicense />
    } else if (this.props.rootStore!.documentStore.document.metadata.getFirst("DublinCore", "type") === DUBLINCORETYPE_KWALITEITSVERKLARING) {
			return <DocumentHtml />
    } else if (this.props.rootStore!.documentStore.document.metadata.getFirst("DublinCore", "type") === DUBLINCORETYPE_BESTEKSPECIFICATIE) {
			return <DocumentHtml />
    } else if (!this.props.rootStore!.documentStore.document.version.node.isSet) {
      return <DocumentCover />
    } else if (this.props.rootStore!.documentStore.document.version.node.mimeType === "application/pdf") {
      return <DocumentPdf />
    } else {
      return <DocumentHtml />
    }
  }
}