import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { LinkManagerTarget } from './../../utils/linkmanager';
import { questUrl } from './../../utils/quest';

interface Props {
  rootStore?: RootStore;
  target: LinkManagerTarget;
  index: number;
}

@inject("rootStore")
@observer
export default class Target extends Component<Props> {
  render() {
    return (
			<div className={"linkmanageritem linkmanageritem-" + (this.props.index % 2 === 0 ? "even" : "odd")}>
        <a href={questUrl(this.props.target.quest.id, this.props.rootStore!.userStore!.kennisID.loggedIn)}><h3>{this.props.target.quest.title}</h3></a>
      </div>
    )
  }
}