import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { PATH_DOCUMENT } from '../../constants';
import { KomoRecentHit } from '../../stores/RecentStore';
import CoverImage from './../CoverImage';

interface Props {
  rootStore?: RootStore;
  recent: KomoRecentHit;
  index: number;
}

@inject("rootStore")
@observer
export default class Recent extends Component<Props> {
  select = (e: React.MouseEvent) => {
    if (!e.ctrlKey) {
      this.props.rootStore!.viewStore.setQuestId(this.props.recent.questId);

      e.preventDefault();
    }
  }

  render() {
    return (
			<div className="recent">
				<table className="maxwidth">
					<tbody>
						<tr>
							<td className="minwidth valign-top">
								<CoverImage docType={this.props.recent.dublinCoretype} />
							</td>
							<td className="maxwith valign-top">
                <a href={PATH_DOCUMENT + this.props.recent.questId} onClick={this.select}><h3>{this.props.recent.questTitle}</h3></a>
								<div className="recent-subtitle">{this.props.recent.subTitle}</div>
								{this.props.recent.license &&
									<div>
									<a className="recent-link" href={PATH_DOCUMENT + this.props.recent.questId} onClick={this.select}>Bekijken</a>
									</div>
								}
								{!this.props.recent.license &&
									<div>geen licentie</div>
								}
							</td>
						</tr>
					</tbody>
				</table>   
      </div>
    )
  }

  private _createMarkup(html: string) {
    return { __html: html };
  }
}