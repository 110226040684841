import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import RootStore from './../stores/RootStore';
import ToggleFullScreen from './ToggleFullScreen';
import logo from '../images/logo-komo-online.svg';
import './Head.css';

interface Props {
	rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class Head extends Component<Props> {
	click = () => {
		//this.props.rootStore!.viewStore.setQuestId("");
	}
	render() {
		return (
			<Row className="header-topline">
				<Col xs={12} sm={8} md={8} lg={9} xl={9}>
					<a href="/"><img className="logo" src={logo} alt="KOMO-online" onClick={this.click}/></a>
				</Col>
				<Col xs={12} sm={4} md={4} lg={3} xl={3} className="last-col">
          <ToggleFullScreen /><br />
          <p className="naw">
					<strong>T:</strong> 085 - 486 24 20<br />
          <strong>E:</strong> info@komo.nl<br />
          <strong>W:</strong> <a href="https://www.komo.nl" target="komo">www.komo.nl</a>
          </p>
				</Col>
			</Row>
		)
	}
}