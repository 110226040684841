import { observable, action, autorun, computed } from 'mobx';
import RootStore from './RootStore';
import { QuestSearchApi, QuestQuery, QuestQueryResult, IQuestQueryHit, QuestFacetSortOrder, QuestFacetSortField, QuestFacetDefinition, QuestReturnFieldDefinition } from '../api/quest';
import { DUBLINCORETYPE_BEOORDELINGSRICHTLIJN, DUBLINCORETYPE_UITVOERINGSRICHTLIJN } from './../constants';

export default class SearchStore {
  private _questSearchApi: QuestSearchApi;

  constructor(private _rootStore: RootStore) {
    this._questSearchApi = new QuestSearchApi();

    autorun(() => {
      this._runQuery();
    });
  }

  @observable
  queryText: string = "";

  @observable
  queryFacetFilters: string = "";

  @observable
  queryPage = 1;

  @observable
  queryDocuments = false;

  @observable
  queryResult = new QuestQueryResult<KomoHit>();

  @observable
  initialQueryExecuted = false;

  @computed
  get moreHitsAvailable(): boolean {
    return this.queryResult.page * this.queryResult.pageSize < this.queryResult.total;
  }

  @computed
  get queryCustomized(): boolean {
    return this.queryText !== "" || this.queryFacetFilters !== "" || this.queryPage !== 1;
  }

  @computed
  get queryFilterCustomized(): boolean {
    return this.queryFacetFilters !== "" || this.queryPage !== 1;
  }

  @action
  setQueryText(value: string) {
    this.queryText = value;
    this._rootStore.viewStore.setViewId("search");
  }

  @action
  setQueryDocuments(value: boolean) {
    this.queryDocuments = value;
  }

  @action
  toggleFacet(term: string, value: string) {
    const hit = this.queryResult.facet.getHit(term, value);

    if (hit) {
      hit.selected = !hit.selected;
      this.queryFacetFilters = this.queryResult.facet.termFilters();
    }
  }

  @action
  resetFilter() {
    this.queryFacetFilters = "";
    this.queryPage = 1;
  }

  @action
  nextPage() {
    if (this.moreHitsAvailable) {
      this.queryPage = this.queryPage + 1;
    }
  }

  private _runQuery = async () => {
    if (this._rootStore.userStore.isReady) {
      const query = new QuestQuery({
        query: this.queryText,
				fields: ["QuestId", "QuestDocumentIdA", "QuestTitle", "QuestDocumentTitle", "DublinCoretitlesubtitle"],
        facetTerms: this._getFacetDefinitions(),
        termFilters: this.queryFacetFilters,
        returnFields: this._getReturnsFields(),
        systemQuery: "+QuestCurrent:1 +QuestOnline:1 +Customapplication:komo",
        page: this.queryPage,
        pageSize: 25,
        sort: this.queryText === "" ? "QuestDate:true" : ""
      });

      if (this.queryDocuments) {
        query.systemQuery += " +QuestIsDocument:1";
        (query.fields as Array<string>).push("RootContents");       
      } else {
        (query.fields as Array<string>).push("Contents");
      }

      const queryresult = await this._questSearchApi.query<KomoHit>(
        { licenseToken: this._rootStore.userStore.questContext.licenseToken, endpoint: this._rootStore.userStore.questContext.endpoint }, query);

      if (this.queryResult.page + 1 === this.queryPage) {
        queryresult.hits = this.queryResult.hits.concat(queryresult.hits);
      }

      this.queryResult = queryresult;
    }
  }

  private _getReturnsFields(): Array<string | QuestReturnFieldDefinition> {
    let list: Array<string | QuestReturnFieldDefinition> =
      ["QuestId", "QuestDocumentId", "QuestTitle", "QuestDocumentTitle", { field: "DublinCoretitlesubtitleNA", mapTo: "subTitle" }, "QuestDate", "DublinCoretype"];

    if (this.queryText && this.queryText !== "") {
      list.push({
        field: this.queryDocuments ? "RootContents" : "Contents",
        summary: true,
        mapTo: "contents"
      });
    }
    return list;
  }

  private _getFacetDefinitions(): QuestFacetDefinition[] {
    return [
      {
        name: "Publicatie",
        term: "DublinCoretype",
        sortOrder: QuestFacetSortOrder.Ascending,
        sortField: QuestFacetSortField.Name
      },
      {
        name: "Status",
        term: "komostatus",
        sortOrder: QuestFacetSortOrder.Ascending,
        sortField: QuestFacetSortField.Name
      },
      {
        name: "Soort",
        term: "CustomSubtype",
        show: (flist) => {
          return flist.hitSelected("DublinCoretype", DUBLINCORETYPE_BEOORDELINGSRICHTLIJN) || flist.hitSelected("DublinCoretype", DUBLINCORETYPE_UITVOERINGSRICHTLIJN);
        },
        sortOrder: QuestFacetSortOrder.Ascending,
        sortField: QuestFacetSortField.Name
      },
      {
        name: "Regeling",
        term: "Customregeling",
        show: (flist) => {
          return flist.hitSelected("DublinCoretype", DUBLINCORETYPE_BEOORDELINGSRICHTLIJN) || flist.hitSelected("DublinCoretype", DUBLINCORETYPE_UITVOERINGSRICHTLIJN);
        },
        sortOrder: QuestFacetSortOrder.Ascending,
        sortField: QuestFacetSortField.Name
      },       
      {
        name: "Uitgever",
        term: "DublinCorepublisher",
        sortOrder: QuestFacetSortOrder.Ascending,
        sortField: QuestFacetSortField.Name
      },
      {
        name: "NEN 2699 Code",
        term: "Customnen2699code",        
        show: (flist) => {
          return flist.hitSelected("DublinCoretype", DUBLINCORETYPE_BEOORDELINGSRICHTLIJN) || flist.hitSelected("DublinCoretype", DUBLINCORETYPE_UITVOERINGSRICHTLIJN);
        },
        sortOrder: QuestFacetSortOrder.Ascending,
        sortField: QuestFacetSortField.Name      
      }
    ];
  }
}

// Typed version of our hits, should match returnFields (but camelCase)
export interface KomoHit extends IQuestQueryHit {
  questId: string;

  questDocumentId: string;

  questTitle: string;

  questDocumentTitle: string;

  subTitle: string;

  contents: string;

  questDate: string;

  dublinCoretype: string;
}