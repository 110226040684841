import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import RootStore from './../../stores/RootStore';
import { QuestTocNode } from './../../api/quest';
import { PATH_DOCUMENT } from './../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

interface Props {
	tocNodes: Array<QuestTocNode>,
	rootStore: RootStore,
}

@observer
export default class Toc extends Component<Props> {
	selectNode = (node: QuestTocNode, e: React.MouseEvent) => {
		if (!e.ctrlKey) {
			if (this.props.rootStore!.documentStore.document.license) {
				this.props.rootStore.viewStore.setQuestId(node.questId);

				if (!node.opened) {
					this.props.rootStore.documentStore.toggleTocNode(node);
				}
			}

			e.preventDefault();
		}
	}

	render() {
		const nodes = this.props.tocNodes;

		return (
			<Fragment>
				{nodes.map(node => {
					const toggleNode = this.props.rootStore.documentStore.toggleTocNode.bind(this, node);
					const selectNode = this.selectNode.bind(this, node);
					return (
						<div key={node.questId} className="toc-childgroup">
							<div className={"toc-item-area" + (this.props.rootStore.viewStore.questId === node.questId ? " selected" : "")}>
								{node.hasChilds && <span className="toc-togglebtn" onClick={toggleNode}><FontAwesomeIcon icon={node.opened ? faCaretDown : faCaretRight} /></span>}
								<a className="toc-item" href={PATH_DOCUMENT + node.questId} onClick={selectNode}>{node.title}</a>
							</div>
							{node.hasChilds && node.opened && node.childs && <Toc tocNodes={node.childs} rootStore={this.props.rootStore} />}
						</div>
					)
				})}
			</Fragment>
		)
	}
}