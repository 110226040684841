import 'babel-polyfill'
import './bootstrap-custom.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import RootStore from './stores/RootStore';
import { startRouter } from './utils/router';

const rootStore = new RootStore();
startRouter(rootStore);

ReactDOM.render(
  <Provider rootStore={rootStore} >
    <App />
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();