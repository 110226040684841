import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { PATH_DOCUMENT } from '../../constants';
import CoverImage from './../CoverImage';
import { DUBLINCORETYPE_BEOORDELINGSRICHTLIJN } from './../../constants';
import './OsfBrlLink.css';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class OsfBrlLink extends Component<Props> {
  select = (e: React.MouseEvent) => {
    if (!e.ctrlKey) {
      const reference = this.props.rootStore!.documentStore.document.metadata.getFirstReference("komo", "osfbrllink");
      if (reference) {
        this.props.rootStore!.viewStore.setQuestId(reference.version.questId);

        e.preventDefault();
      }
    }
  }

  render() {
    const reference = this.props.rootStore!.documentStore.document.metadata.getFirstReference("komo", "osfbrllink");

    if (reference) {
      const subtitle = reference.metadata.getFirst("DublinCore", "title.subtitle");

      return (
				<div className="osfbrllinklist borderbox scrollxauto">
					<h2>BRL verwijzing</h2>

					<div className="osfbrllinkitem">
						<table className="maxwidth">
							<tbody>
								<tr>
									<td className="minwidth valign-top">
										<CoverImage docType={DUBLINCORETYPE_BEOORDELINGSRICHTLIJN} />
									</td>
									<td className="maxwith valign-top">
										<h3>{reference.versionTitle}</h3>
										<div className="subtitle">{subtitle}</div>
                    <div>
                      <a className="osfbrllinkitem-link" href={PATH_DOCUMENT + reference.version.questId} onClick={this.select}>Bekijken</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
      );
    } else {
      return null;
    }
  }
}