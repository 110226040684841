import { KENNISIDURL } from './../constants';

export class KennisIDInfo {
  loggedIn: boolean = false;

  email: string = "";

  name: string = "";

  uniqueId: string = "";

  organisationName: string = "";
}

export function getKennisIDRedirectUrl() {  
  return `${KENNISIDURL}/retrievetoken?returnurl=${encodeURIComponent(getKennisIDCallbackUrl())}`;
}

export function getKennisIDLoginUrl() {  
  return `${KENNISIDURL}/authenticate?returnurl=${encodeURIComponent(getKennisIDCallbackUrl())}`;
}

export function getKennisIDActiveProfile() {
	return `${KENNISIDURL}/profiel`;
}

function getKennisIDCallbackUrl() {

  return window.location.protocol + "//" + window.location.host + "/kennisid/callback?token={0}&returnurl=" + encodeURIComponent(window.location.href);
}