import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import KvBrlLink from './KvBrlLink';
import './KvBrlLinkList.css';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class KvBrlLinkList extends Component<Props> {
  render() {
    const referencelist = this.props.rootStore!.documentStore.document.metadata.getReference("komo", "kvbrllink");
    if (referencelist.length === 0) {
      return null;
    } else {
      return (
				<div className="kvbrllinklist borderbox scrollxauto">
					<h2>Beoordelingsrichtlijnen</h2>
					<div className="kvbrllinklist-statistics"><strong>{referencelist.length}</strong> {referencelist.length === 1 ? "resultaat" : "resultaten"}</div>
          {referencelist.map((questReference, index) => <KvBrlLink brlDocument={questReference} key={questReference.questId} />)}
        </div>
      )
    }
  }
}