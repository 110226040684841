import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import QueryResult from './QueryResult';
import RootStore from '../../stores/RootStore';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class QueryResultList extends Component<Props> {
  more = (e: React.MouseEvent) => {
    this.props.rootStore!.documentSearchStore.nextPage();
    e.preventDefault();
  }

  render() {
    return (
      <div className="resultlist">
        <div className="statistics"><strong>{this.props.rootStore!.documentSearchStore.queryResult!.total}</strong> {this.props.rootStore!.documentSearchStore.queryResult!.total === 1 ? "resultaat" : "resultaten"}</div>
        {this.props.rootStore!.documentSearchStore.queryResult!.hits.map((result, index) => <QueryResult result={result} index={index} key={result.questId} />)}

        {this.props.rootStore!.documentSearchStore.moreHitsAvailable &&
          <div><a href="#" onClick={this.more}>meer laden</a></div>
        }
      </div>
    )
  }
}