import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import Result from './Result';
import RootStore from '../../stores/RootStore';
import './ResultList.css';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class ResultList extends Component<Props> {
	more = (e: React.MouseEvent) => {
    this.props.rootStore!.searchStore.nextPage();
    e.preventDefault();
  }

  render() {
    return (
			<div className="resultlist">
				<h2>Resultaten</h2>
        <div className="statistics"><strong>{this.props.rootStore!.searchStore.queryResult!.total}</strong> {this.props.rootStore!.searchStore.queryResult!.total === 1 ? "resultaat" : "resultaten"}</div>

				{this.props.rootStore!.searchStore.queryResult!.hits.map((result, index) => <Result result={result} index={index} key={result.questId} />)}

        {this.props.rootStore!.searchStore.moreHitsAvailable &&
					<div><br /><Button color="primary" onClick={this.more}>Meer laden...</Button><br /><br /></div>
        }
      </div>
    )
  }
}