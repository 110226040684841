import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { QuestVersionMapItemType } from '../../api/quest/types-versionmap';
import HistoryItem from './HistoryItem';
import RootStore from '../../stores/RootStore';

interface Props {
	rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class HistoryList extends Component<Props> {
	render() {
		var scounter = 0; var rcounter = 0; var pcounter = 0;
		this.props.rootStore!.documentHistoryStore.versionMap.list.forEach( (item, index)=> {
			item.type === QuestVersionMapItemType.Successor ? scounter++ : item.type === QuestVersionMapItemType.Predecessor ? pcounter++ : rcounter++;			
		});
		return (
			<table className="history-table"><tbody>								
				<tr className={scounter > 0 ? "historytyperow" : "historytyperow hidden"}>
						<td className="maxwidth">
						<div className="historylist scrollxauto">
							{this.props.rootStore!.documentHistoryStore.versionMap.list.map((item, index) => <HistoryItem item={item} index={index} versionType={QuestVersionMapItemType.Successor} key={item.questId + item.version} />).reverse()}
							</div>
						</td>
						<td className="minwidth historytypeblock greenback">
							<div className="history-revision-caption">Opvolgers</div>
						</td>
					</tr>
				<tr className={rcounter > 0 ? "historytyperow" : "historytyperow hidden"}>
						<td className="maxwidth">
							<div className="historylist scrollxauto">
							{this.props.rootStore!.documentHistoryStore.versionMap.list.map((item, index) => <HistoryItem item={item} index={index} versionType={QuestVersionMapItemType.Revision} key={item.questId + item.version} />).reverse()}
							</div>
						</td>
						<td className="minwidth historytypeblock blueback">
							<div className="history-revision-caption">Revisies</div>
						</td>
					</tr>
				<tr className={pcounter > 0 ? "historytyperow" : "historytyperow hidden"}>
						<td className="maxwidth">
							<div className="historylist scrollxauto">
							{this.props.rootStore!.documentHistoryStore.versionMap.list.map((item, index) => <HistoryItem item={item} index={index} versionType={QuestVersionMapItemType.Predecessor} key={item.questId + item.version} />).reverse()}
							</div>
						</td>
						<td className="minwidth historytypeblock orangeback">
							<div className="history-revision-caption">Voorgangers</div>
						</td>
					</tr>
			</tbody></table>

		);
	}
}