import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import FacetList from './FacetList';
import DocumentFilter from './DocumentFilter';
import ResetQuery from './ResetQuery';
import './Filters.css';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class Filters extends Component<Props> {
  resetFilter = (e: React.MouseEvent) => {
    this.props.rootStore!.searchStore.resetFilter();
    e.preventDefault();
  }

	render() {

    return (
      <div className="borderbox filters">
        <h2>Filteren</h2>
        <ResetQuery />
        <DocumentFilter />
        <FacetList />
      </div>
    )
  }
}