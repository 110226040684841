import { observable, action, autorun } from 'mobx';
import { QuestDocumentApi, QuestVersionMap } from '../api/quest';
import RootStore from './RootStore';

export default class DocumentHistoryStore {
  private _questDocumentApi: QuestDocumentApi;

  constructor(private _rootStore: RootStore) {
    this._questDocumentApi = new QuestDocumentApi();

    autorun(() => {
      this._loadHistory();
    });
  }

  @observable
  versionMap = new QuestVersionMap();

  @observable
  open = false;

  @action
  setOpen(value: boolean) {
    this.open = value;
  }

  private _loadHistory = async () => {
    if (this._rootStore.documentStore.document.questId !== this.versionMap.questId && this.open) {      
      this.versionMap = await this._questDocumentApi.getVersionMap(
        { licenseToken: this._rootStore.userStore.questContext.licenseToken, endpoint: this._rootStore.userStore.questContext.endpoint },
        this._rootStore.documentStore.document.questId, {
          includeMetadata: true
        }); 
    }
  }
}