import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import CookieConsent from 'react-cookie-consent';
import RootStore from '../stores/RootStore';

interface Props {
  rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class CookieDisclaimer extends Component<Props> {
  componentDidMount() {
    if (window.document.cookie.indexOf("CookieConsent=true") !== -1) {
      this.props.rootStore!.AnalyticsStore.activate();
    }
  }

  render() {
    const isbot = window.navigator.userAgent.indexOf('Googlebot') > 0;

    // zie: https://www.npmjs.com/package/react-cookie-consent
    return (
      <>
        {!isbot &&
          <CookieConsent
            buttonText="Akkoord"
            cookieName="CookieConsent"
            onAccept={this.props.rootStore!.AnalyticsStore.activate}
            buttonStyle={{ backgroundColor: "#ee7a12", color: "#fff" }}>
            KOMO-online maakt gebruik van cookies om ervoor te zorgen dat onze website optimaal functioneert.
            Tevens worden cookies gebruikt om informatie over het gebruik van de website te verzamelen en te analyseren ter verbetering van de website.

            Door op akkoord te klikken, of door gebruik te maken van deze website, gaat u hiermee akkoord.
          </CookieConsent>
        }
      </>
    );
  }
}