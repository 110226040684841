import UserStore from './UserStore';
import ViewStore from './ViewStore';
import SearchStore from './SearchStore';
import DocumentStore from './DocumentStore';
import DocumentSearchStore from './DocumentSearchStore';
import DocumentHistoryStore from './DocumentHistoryStore';
import DocumentLookupKvStore from './DocumentLookupKvStore';
import DocumentLookupOsfStore from './DocumentLookupOsfStore';
import RecentStore from './RecentStore';
import LinkManagerStore from './LinkManagerStore';
import AnalyticsStore from './AnalyticsStore';

export default class RootStore {
  userStore = new UserStore(this);

  viewStore = new ViewStore(this);

  searchStore = new SearchStore(this);

  documentStore = new DocumentStore(this);

  documentSearchStore = new DocumentSearchStore(this);

  documentHistoryStore = new DocumentHistoryStore(this);

  documentLookupKvStore = new DocumentLookupKvStore(this);

  documentLookupOsfStore = new DocumentLookupOsfStore(this);

  recentStore = new RecentStore(this);

  linkManagerStore = new LinkManagerStore(this);

  AnalyticsStore = new AnalyticsStore(this);
}