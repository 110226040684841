import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container } from 'reactstrap';
import Head from './Head';
import Foot from './Foot';
import CookieDisclaimer from './CookieDisclaimer';
import Navigation from './Navigation';
import RootStore from '../stores/RootStore';

interface Props {
	rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class Layout extends Component<Props> {
	render() {
		return (
			<Container fluid={this.props.rootStore!.viewStore.fullScreen}>
        <Head />
				<Navigation />
				{this.props.children}    
        <Foot />
        <CookieDisclaimer />
      </Container>
    );
  }
}