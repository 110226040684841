import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

interface Props {
	rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class Print extends Component<Props> {
	
	print = () => {
		let element: HTMLIFrameElement = document.getElementById("ifmcontentstoprint") as HTMLIFrameElement;
		let contents: HTMLElement = document.getElementById("divcontents") as HTMLElement;
		let iframedom = element.contentWindow;	
		if (iframedom !== null) {
			iframedom.document.close();
			iframedom.document.open();
			iframedom.document.write("<html><head><link rel='stylesheet' type='text/css' href='/PrintStyles.css' /></head><body><div class='printhtml'>" + contents.outerHTML + "</div></body></html>");
			iframedom.focus();
			window.setTimeout(function () { if (iframedom !== null) { iframedom.print(); } }, 2000);			
		}
	}

	render() {
		return (
		<Fragment>
			<iframe id="ifmcontentstoprint" className="printframe" title="KOMO-online"></iframe>
			<Button className="btn-round" size="sm" color="secondary" onClick={this.print}><FontAwesomeIcon icon={faPrint} /> Printen</Button>
		</Fragment>
		)
	}
}