import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import RootStore from './../stores/RootStore';
import Content from './document/Content';
import Navigation from './document/Navigation';
import Query from './search/Query';
import KvLookupList from './kvlookup/KvLookupList';
import OsfLookupList from './osflookup/OsfLookupList';
import DownloadKomoCatalogus from './downloadkomocatalogus/DownloadKomoCatalogus';
import OsfBrlLink from './document/OsfBrlLink';
import KvBrlLinkList from './document/KvBrlLinkList';
import History from './document/History';
import Share from './document/Share';
import Print from './document/Print';
import ToggleNav from './document/ToggleNav';
import LinkManager from './linkmanager/LinkManager';
import './Document.css';

interface Props {
  rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class Document extends Component<Props> {
	render() {
		var doctype = this.props.rootStore!.documentStore.document.metadata.getFirst("DublinCore", "type");
		if (doctype == null) {
			doctype = "";
		}
    return (
      <Fragment>
        <LinkManager />
        <Row className="document-area">
          <Col xs={12} sm={12} md={12} lg={9} xl={9} className="nopadding">
            <Query />

            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<h1 className="document-title">{this.props.rootStore!.documentStore.document.versionTitle}</h1>
								<div className="document-toolbar">
									<ToggleNav isVisible={this.props.rootStore!.documentStore.tocNodes.length > 0}/>
                  <History />
                  <Print />
                  <Share />
                </div>
              </Col>
            </Row>
            <Row>
              {this._renderDocument()}
            </Row>
          </Col>
					<Col xs={12} sm={12} md={12} lg={3} xl={3} className="nopadding-right sidebar">
            <DownloadKomoCatalogus docType={doctype} />
            <OsfBrlLink />
            <KvBrlLinkList />
            <KvLookupList />
            <OsfLookupList />
          </Col>
        </Row>
        <Row className="bottomline">
          <Col className="nopadding">
            <hr />
          </Col>
        </Row>
      </Fragment>
    );
  }

	private _renderDocument() {
		if ((this.props.rootStore!.documentStore.tocNodes.length > 0) && (this.props.rootStore!.viewStore.tocVisible)) {
      return (
        <Fragment>
					<Col xs={12} sm={12} md={4} lg={4} xl={4} className="document-toc">
            <Navigation />
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className="document-content">
            <Content />
          </Col>
        </Fragment>
      );
    } else {
      return (
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="document-content">
          <Content />
        </Col>
      );
    }
  }
}