import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { PATH_DOCUMENT } from '../../constants';
import { formatDate } from '../../utils/formatters';
import { KomoHit } from '../../stores/SearchStore';
import CoverImage from './../CoverImage';

interface Props {
	rootStore?: RootStore;
	result: KomoHit;
	index: number;
}

@inject("rootStore")
@observer
export default class Result extends Component<Props> {
	select = (e: React.MouseEvent) => {
		if (!e.ctrlKey) {
			this.props.rootStore!.viewStore.setQuestId(this.props.result.questId);

			e.preventDefault();
		}
	}

  render() {
    let title = this.props.result.questTitle;
    if (this.props.result.questTitle !== this.props.result.questDocumentTitle) {
      title += " - " + this.props.result.questDocumentTitle;
    }

		return (
			<div className={"result result-" + (this.props.index % 2 === 0 ? "even" : "odd")}>
				<table className="maxwidth">
					<tbody>
						<tr>
							<td className="valign-top minwidth">
								<CoverImage docType={this.props.result.dublinCoretype} />
							</td>
							<td className="maxwith valign-top">
                <a href={PATH_DOCUMENT + this.props.result.questId} onClick={this.select}><h3>{title}</h3></a>
								<p className="subtitle">{this.props.result.subTitle}</p>
								<p className="summary" dangerouslySetInnerHTML={this._createMarkup(this.props.result.contents)}></p>

								{this.props.result.license &&
									<p>
										<span className="badge badge-secondary">{this.props.result.dublinCoretype}</span>
										<span className="pubdate">Publicatiedatum: <strong>{formatDate(this.props.result.questDate)}</strong></span><br/>
										<a className="link" href={PATH_DOCUMENT + this.props.result.questId} onClick={this.select}>Bekijken</a>
									</p>
								}
								{!this.props.result.license &&
									<div>geen licentie</div>
								}
								<div className="clear"></div>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
		)
	}

	private _createMarkup(html: string) {
		return { __html: html };
	}
}