import { observable, action, reaction } from 'mobx';
import { GOOGLEANALYTICS } from './../constants';
import ReactGA from 'react-ga';
import RootStore from './RootStore';

export default class AnalyticsStore {
  private page = "";

  constructor(private _rootStore: RootStore) {
    reaction(() => { return { q: this._rootStore.documentStore.document.version.questId, nq: this._rootStore.documentStore.document.version.node.questId } }, (data, reaction) => {
      this._updateQuestId();
    });
  }

  @observable
  activated = false;

  @action
  activate = () => {
    if (!this.activated) {
      this.activated = true;

      ReactGA.initialize(GOOGLEANALYTICS);
      this.page = window.location.pathname + window.location.search;
      ReactGA.pageview(this.page);
    }
  }

  private _updateQuestId = async () => {    
      if (this.activated) {
      if (window.location.pathname + window.location.search !== this.page) {
        this.page = window.location.pathname + window.location.search;

        ReactGA.pageview(this.page);
      }
    }
  }
}