import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOutdent } from '@fortawesome/free-solid-svg-icons';
import { faIndent } from '@fortawesome/free-solid-svg-icons';

interface Props {
	rootStore?: RootStore;
	isVisible: boolean;
}

@inject("rootStore")
@observer
export default class ToggleNav extends Component<Props> {
	toggle = () => {
		this.props.rootStore!.viewStore.toggleTocVisible();
	}

	render() {
		if (this.props.isVisible) {
			return (
				<Button className="btn-round left" size="sm" color="secondary"
					onClick={this.toggle}
					title={this.props.rootStore!.viewStore.tocVisible ? "Inhoudsopgave verbergen" : "Inhoudsopgave tonen"} >
					<FontAwesomeIcon
						icon={this.props.rootStore!.viewStore.tocVisible ? faOutdent : faIndent} /> </Button>
			);
		} else {
			return null;
		}
	}
}