import { observable, action, computed } from 'mobx';
import RootStore from './RootStore';
import { VIEW_SEARCH, VIEW_DOCUMENT, PATH_SEARCH, PATH_DOCUMENT } from './../constants';

export default class ViewStore {
  constructor(private rootStore: RootStore) {

  }

  @observable
  viewId: string = VIEW_SEARCH;

  @observable
  questId: string = "";

  @observable
  tocVisible: boolean = true;

  @observable
  fullScreen: boolean = true;

  @action
  setViewId(viewId: string) {
    this.viewId = viewId;
  }

  @action
  setQuestId(questId: string) {
    this.questId = decodeURIComponent(questId);
    if (this.hasQuestId) {
      this.viewId = VIEW_DOCUMENT;
    }
  }

  @action
  toggleTocVisible() {
    this.tocVisible = !this.tocVisible;
  }

  @action
  toggleFullScreen() {
    this.fullScreen = !this.fullScreen;
  }

  @computed
  get hasQuestId() {
    return this.questId !== "";
  }

  @computed
  get currentPath() {
    switch (this.viewId) {
      case VIEW_SEARCH:
        return PATH_SEARCH;
      case VIEW_DOCUMENT:
        let path = PATH_DOCUMENT;

        if (this.questId) {
          path += `${this.questId}`;
        }

        return path;
      default:
        return null;
    }
  }

  @computed
  get currentTitle() {
    return "KOMO-online";
  }
}