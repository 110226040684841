import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Target from './Target';
import RootStore from '../../stores/RootStore';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class TargetList extends Component<Props> {
  render() {
    return (
			<div className="linkmanagerlist">
        {this.props.rootStore!.linkManagerStore.targetList.filter((value) => value.type === "Quest").map((target, index) => <Target target={target} index={index} key={target.quest.id} />)}
      </div>
    );
  }
}