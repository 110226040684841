import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import HistoryList from './HistoryList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import './History.css';

interface Props {
	rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class History extends Component<Props> {
	toggle = () => {
		this.props.rootStore!.documentHistoryStore.setOpen(!this.props.rootStore!.documentHistoryStore.open);
	}

	render() {
		return (
			<Fragment>
				<Button className="btn-round" size="sm" color="secondary" onClick={this.toggle}><FontAwesomeIcon icon={faClock} /> Versiehistorie</Button>
				<Modal isOpen={this.props.rootStore!.documentHistoryStore.open} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Versiehistorie</ModalHeader>
					<ModalBody>											
						<HistoryList />														
					</ModalBody>
				</Modal>
			</Fragment>
		);
	}
}