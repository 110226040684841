import { pickForTarget, toCamelCase } from './utils';
import { QuestFacetList, QuestFacetDefinition } from './types-facet';

export interface IQuestQuery {
  query?: string;

  page?: number;

  pageSize?: number;

  fields?: string | Array<string>;

  returnFields?: string | Array<string | QuestReturnFieldDefinition>;

  facetTerms?: string | Array<QuestFacetDefinition>;

  termFilters?: string;

  systemQuery?: string;

  sort?: string;

  omitLicenseInvisible?: boolean;

  licenseFilteredCount?: number;
}

export class QuestQuery implements IQuestQuery {
  constructor(query?: IQuestQuery) {
    Object.assign(this, query);
  }

  query?: string;

  page?: number = 1;

  pageSize?: number = 25;

  fields?: string | Array<string> = "QuestId,QuestTitle";

  returnFields?: string | Array<string | QuestReturnFieldDefinition> = "QuestId,QuestTitle";

  facetTerms?: string | Array<QuestFacetDefinition>;

  termFilters?: string;

  systemQuery?: string;

  sort?: string;

  omitLicenseInvisible?= false;

  licenseFilteredCount?: number;

  flatten(queryResult: QuestQueryResult<any>): void {
    if (Array.isArray(this.fields)) {
      this.fields = this.fields.join(",");
    }

    if (Array.isArray(this.facetTerms)) {
      const list = this.facetTerms.map((definition) => {
        return `${definition.term}=${definition.name}`;
      });

      queryResult.facet.definitionList = this.facetTerms;
      this.facetTerms = list.join(",");
    }

    if (Array.isArray(this.returnFields)) {
      const list = this.returnFields.map((definition) => {
        if (typeof definition == "string") {
          return definition;
        } else {
          return `${definition.field}${definition.summary ? "*" : ""}`;
        }
      });

      queryResult.returnFieldsDefinitionList = this.returnFields;
      this.returnFields = list.join(",");
    }
  }
}

export class QuestQueryResult<T extends IQuestQueryHit> {
  fromServer(data: any) {
    pickForTarget(data, this, "hit", "facet");
    this._hitsFromServer(data);
    this.facet.fromServer(data);
  }

  total = 0;

  page = 1;

  pageSize = 0;

  hits = new Array<T>();

  facet = new QuestFacetList();

  returnFieldsDefinitionList?: Array<string | QuestReturnFieldDefinition>;

  private _hitsFromServer(data: any) {
    data.Hit.forEach((hitData: any) => {
      let hit = {} as T;

      Object.assign(hit, toCamelCase(hitData));
      hit.license = hitData.LicenseType !== "Hidden";

      if (this.returnFieldsDefinitionList) {
        this.returnFieldsDefinitionList.forEach((definition) => {
          if (typeof definition != "string" && definition.mapTo) {
            ((hit as any)[definition.mapTo!]) = hitData[definition.field];
          }
        });
      }

      this.hits.push(hit);
    });
  }
}

export interface IQuestQueryHit {
  license: boolean;
}

export interface QuestReturnFieldDefinition {
  field: string

  summary?: boolean;

  mapTo?: string;
}