import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import TargetList from './TargetList';
import './LinkManager.css';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class LinkManager extends Component<Props> {
  toggle = () => {
    this.props.rootStore!.linkManagerStore.setOpen(!this.props.rootStore!.linkManagerStore.open);
  }

  render() {
    return (
      <Modal isOpen={this.props.rootStore!.linkManagerStore.open} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Verwijzingen</ModalHeader>
				<ModalBody>
					<table className="linkmanager-table"><tbody><tr>
						<td className="maxwidth">
							<TargetList />
						</td>
						<td className="minwidth blueback">
							<div className="linkmanager-revision-caption">Targets</div>
						</td>
					</tr>
					</tbody></table>         
        </ModalBody>
      </Modal>
    );
  }
}