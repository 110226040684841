import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Layout from './components/Layout';
import Search from './components/Search';
import Document from './components/Document';
import RootStore from './stores/RootStore';
import { VIEW_DOCUMENT } from './constants';
import './App.css';
import './ModalDialog.css';

interface Props {
	rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class App extends Component<Props> {
	render() {
		if (this.props.rootStore!.userStore.isReady) {
			return (
				<Layout>
					{this._renderCurrentPage()}
				</Layout>
			);
		} else {
			return null;
		}
	}

	private _renderCurrentPage() {
		const pageid = this.props.rootStore!.viewStore.viewId;
		switch (pageid) {
			case VIEW_DOCUMENT:
				return <Document />
			default:
				return <Search />
		}
	}
}
