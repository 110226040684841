import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { PATH_DOCUMENT } from '../../constants';
import { KomoKvHit } from '../../stores/DocumentLookupKvStore';
import CoverImage from './../CoverImage';
import { DUBLINCORETYPE_KWALITEITSVERKLARING } from './../../constants';

interface Props {
	rootStore?: RootStore;
	kvlookupitem: KomoKvHit;
	index: number;
}

@inject("rootStore")
@observer
export default class KvLookupItem extends Component<Props> {
	select = (e: React.MouseEvent) => {
		if (!e.ctrlKey) {
			this.props.rootStore!.viewStore.setQuestId(this.props.kvlookupitem.questId);

			e.preventDefault();
		}
	}

	render() {
		return (
			<div className="kvlookupitem">
				<table className="maxwidth">
					<tbody>
						<tr>
							<td className="minwidth valign-top">
                <CoverImage docType={DUBLINCORETYPE_KWALITEITSVERKLARING} />
							</td>
							<td className="maxwith valign-top">
                <a href={PATH_DOCUMENT + this.props.kvlookupitem.questId} onClick={this.select}><h3>{this.props.kvlookupitem.questTitle}</h3></a>
								<div className="subtitle">{this.props.kvlookupitem.subTitle}</div>
								{this.props.kvlookupitem.license &&
									<div>
									<a className="kvlookupitem-link" href={PATH_DOCUMENT + this.props.kvlookupitem.questId} onClick={this.select}>Bekijken</a>
									</div>
								}
								{!this.props.kvlookupitem.license &&
									<div>geen licentie</div>
								}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}