import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import logo from '../images/logo-komo.svg';
import './Foot.css';

export default class Foot extends Component {
  render() {
    return (
      <Fragment>
        <Row className="footer-topline">
          <Col xs={12} sm={12} md={6} lg={5} xl={4} className="first-col">
            <h4>Adresgegevens</h4>
            <img className="logo" src={logo} alt="KOMO-logo" />
            <p>
              Burgemeester van Reenensingel 101<br />
              Postbus 420<br />
              2800 AK Gouda
						</p>
          </Col>
          <Col xs={12} sm={12} md={3} lg={4} xl={4}>
            <h4>Social media</h4>
            <p>
              <a href="https://www.linkedin.com/company/stichtingkomo" target="komo">KOMO op LinkedIn</a><br />
              <a href="https://www.youtube.com/channel/UC7c2d_vUrklC6PZB13kWWEg" target="komo">KOMO op YouTube</a>
            </p>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} xl={4}>
            <h4>Overig</h4>
            <p>
              <a href="https://www.komo.nl/disclaimer/" target="komo">Disclaimer</a><br />
              <a href="https://www.komo.nl/privacy/" target="komo">Privacy en Cookieverklaring</a>
            </p>
          </Col>
        </Row>
        <Row className="footer-bottomline">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p>2020 © KOMO</p>
          </Col>
        </Row>
      </Fragment>
    );
  }
}