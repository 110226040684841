import React, { Component } from 'react';
import './CoverImage.css';
import coverbeoordelingsrichtlijn from '../images/cover/beoordelingsrichtlijn.svg';
import coverkwaliteitsverklaring from '../images/cover/kwaliteitsverklaring.svg';
import coverbestekspecificatie from '../images/cover/bestekspecificatie.svg';
import coverunknown from '../images/cover/unknown.svg';
import { DUBLINCORETYPE_BESTEKSPECIFICATIE, DUBLINCORETYPE_BEOORDELINGSRICHTLIJN, DUBLINCORETYPE_UITVOERINGSRICHTLIJN, DUBLINCORETYPE_KWALITEITSVERKLARING } from './../constants';

interface Props {
	docType?: string;
}

export default class CoverImage extends Component<Props> {
	render() {
    if ((this.props.docType === DUBLINCORETYPE_BEOORDELINGSRICHTLIJN) || (this.props.docType === DUBLINCORETYPE_UITVOERINGSRICHTLIJN)) {
			return <img className="coverimage" src={coverbeoordelingsrichtlijn} alt="beoordelingsrichtlijn" />
    } else if (this.props.docType === DUBLINCORETYPE_KWALITEITSVERKLARING) {
			return <img className="coverimage" src={coverkwaliteitsverklaring} alt="kwaliteitsverklaring" />
    } else if (this.props.docType === DUBLINCORETYPE_BESTEKSPECIFICATIE) {
			return <img className="coverimage" src={coverbestekspecificatie} alt="bestekspecificatie" />
		} else {
			return <img className="coverimage" src={coverunknown} alt="onbekend" />
		}
	}
}