import { BRLPREFIX, URLPREFIX, PBLPREFIX, OSFPREFIX, KVPREFIX, URLBRISWARENHUIS, URLBOUWBESLUITONLINE } from './../constants';

export function getBrlLikePrefix(id: string | null): string | undefined {
  if (id) {
    if (id.startsWith(BRLPREFIX)) {
      return BRLPREFIX;
    } else if (id.startsWith(URLPREFIX)) {
      return URLPREFIX;
    } else if (id.startsWith(PBLPREFIX)) {
      return PBLPREFIX;
    }
  }
}

export function hasInternalPrefix(id: string): boolean {
  if (id && (id.startsWith(BRLPREFIX) || id.startsWith(URLPREFIX) || id.startsWith(PBLPREFIX) ||
    id.startsWith(OSFPREFIX) || id.startsWith(KVPREFIX))) {
    return true;
  } else {
    return false;
  }
}

export function questUrl(id: string, loggedIn: boolean) {
  if (hasInternalPrefix(id)) {
    return id;
  } else {
    return (loggedIn ? URLBRISWARENHUIS : URLBOUWBESLUITONLINE) + "/" + id;
  }
}