import { observable, action, computed, autorun } from 'mobx';
import { QuestSearchApi, QuestQuery, QuestQueryResult, IQuestQueryHit } from './../api/quest';
import RootStore from './RootStore';
import { DUBLINCORETYPE_BESTEKSPECIFICATIE, DUBLINCORETYPE_UITVOERINGSRICHTLIJN, DUBLINCORETYPE_BEOORDELINGSRICHTLIJN } from './../constants';

export default class DocumentLookupOsfStore {
  private _questSearchApi: QuestSearchApi;

  constructor(private _rootStore: RootStore) {
    this._questSearchApi = new QuestSearchApi();

    autorun(() => {
      this._loadOsfList();
    });
  }

  @observable
  queryPage = 1;

  @observable
  queryResult = new QuestQueryResult<KomoOsfHit>();

  @observable
  questId = "";

  @computed
  get moreHitsAvailable(): boolean {
    return this.queryResult.page * this.queryResult.pageSize < this.queryResult.total;
  }

  @action
  nextPage() {
    if (this.moreHitsAvailable) {
      this.queryPage = this.queryPage + 1;
    }
  }
  
  private _loadOsfList = async () => {
    if ((this._rootStore.documentStore.document.questId !== this.questId) || (this.queryPage !== this.queryResult.page)) {
      if (this._rootStore.documentStore.document.questId !== this.questId) {
        this.questId = this._rootStore.documentStore.document.questId;
        this.queryPage = 1;
      }
            
      const type = this._rootStore.documentStore.document.metadata.getFirst("DublinCore", "type");      

      if (type === DUBLINCORETYPE_UITVOERINGSRICHTLIJN || type === DUBLINCORETYPE_BEOORDELINGSRICHTLIJN) {
        const brl = this._rootStore.documentStore.document.version.title;  

        const query = new QuestQuery({
          fields: "QuestId",
          returnFields: ["QuestId", "QuestTitle", { field: "DublinCoretitlesubtitleNA", mapTo: "subTitle" }],          
          systemQuery: `+QuestCurrent:1 +QuestOnline:1 +QuestIsDocument:1 -komostatus:Vervallen +Customapplication:komo +DublinCoretype:"${DUBLINCORETYPE_BESTEKSPECIFICATIE}" +komoosfbrl:"${brl}"`,
          page: this.queryPage,
          pageSize: 5
        });


        const queryresult = await this._questSearchApi.query<KomoOsfHit>(
          { licenseToken: this._rootStore.userStore.questContext.licenseToken, endpoint: this._rootStore.userStore.questContext.endpoint }, query);

        if (this.queryResult.page + 1 === this.queryPage) {
          queryresult.hits = this.queryResult.hits.concat(queryresult.hits);
        }

        this.queryResult = queryresult;
      } else {
        this.queryResult = new QuestQueryResult<KomoOsfHit>();
      }
    }
  }
}

// Typed version of our hits, should match returnFields (but camelCase)
export interface KomoOsfHit extends IQuestQueryHit {
  questId: string;

  questTitle: string;

  subTitle: string;
}