import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getKennisIDLoginUrl, getKennisIDActiveProfile, KennisIDInfo } from './../../utils/kennisid';
import kennisidlogo from '../../images/KennisID-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface Props {  
  kennisIDInfo: KennisIDInfo;
}

@observer
export default class KennisIDStatus extends Component<Props> {
  render() {
		if (!this.props.kennisIDInfo.loggedIn) {		
      const url = getKennisIDLoginUrl();		
      return (
        <div className="kennisid-area hidden-xs">
					<a className="kennisid-link" href={url}>Inloggen met KennisID</a> <img src={kennisidlogo} className="kennisidlogo" alt="KennisID" />
        </div>
      )
		} else {
			const profileurl = getKennisIDActiveProfile();
      return (
				<div className="kennisid-area hidden-xs">
					<FontAwesomeIcon icon={faUser} /> <a className="kennisid-link" href={profileurl}>{this.props.kennisIDInfo.name}</a> <img src={kennisidlogo} className="kennisidlogo" alt="KennisID" />
				</div>
      )
    }
  }
}