import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { CustomInput, FormGroup, Label, Badge } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import { QuestFacetHit } from './../../api/quest';

interface Props {
  rootStore?: RootStore;
  hit: QuestFacetHit;
}

@inject("rootStore")
@observer
export default class FacetHit extends Component<Props> {
  change = () => {
    this.props.rootStore!.searchStore.toggleFacet(this.props.hit.facet.term, this.props.hit.value);
  }

  render() {
    return (
      <FormGroup check>
        <Label className="facet" check>
          <CustomInput className="facet-checkbox" type="checkbox" id={this.props.hit.value} onChange={this.change} checked={this.props.hit.selected} label={this.props.hit.name} /> <Badge className="badge" color="secondary">{this.props.hit.count}</Badge>
        </Label>
      </FormGroup>      
    )
  }
}