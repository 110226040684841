import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { PATH_DOCUMENT } from '../../constants';
import { formatDate } from '../../utils/formatters';
import { QuestVersionMapItem, QuestVersionMapItemType } from '../../api/quest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

interface Props {
  rootStore?: RootStore;
	item: QuestVersionMapItem;
	versionType: QuestVersionMapItemType;
  index: number;
}

@inject("rootStore")
@observer
export default class HistoryItem extends Component<Props> {
  select = (e: React.MouseEvent) => {
    if (!e.ctrlKey) {
      this.props.rootStore!.viewStore.setQuestId(this.props.item.questId);
      this.props.rootStore!.documentHistoryStore.setOpen(false);

      e.preventDefault();
    }
  }

  compare = (e: React.MouseEvent) => {
    this.props.rootStore!.documentStore.setCompareVersion(this.props.item.version);
    this.props.rootStore!.documentHistoryStore.setOpen(false);

    e.preventDefault();
  }

  cancelCompare = (e: React.MouseEvent) => {
    this.props.rootStore!.documentStore.setCompareVersion(undefined);
    this.props.rootStore!.documentHistoryStore.setOpen(false);

    e.preventDefault();
  }

  render() {
    const viewing = this.props.rootStore!.documentStore.document.version.questId === this.props.item.questId;
    const allowcompare = !viewing && this.props.item.type === QuestVersionMapItemType.Revision;
    const compareisactive = this.props.rootStore!.documentStore.compareVersion === this.props.item.version &&
      this.props.item.type === QuestVersionMapItemType.Revision;

    if (this.props.item.type === this.props.versionType) {
      if (viewing) {
        return (
          <div className={"historyitem historyitem-" + (this.props.index % 2 === 0 ? "even" : "odd")}>
            <span>
              {viewing &&
                <FontAwesomeIcon className="history-eye" icon={faEye} title="In beeld" />
              }
            </span>

            <h3>{this.props.item.title}</h3>

            <div className="history-subtitle">
              {this.props.item.metadata.getFirst("DublinCore", "title.subtitle")}
            </div>
            <div>
              <strong>Datum:</strong> <span className="history-pubdate">{formatDate(this.props.item.date)}</span>
            </div>

            {!viewing &&
              <div className="align-right">
                {allowcompare && !compareisactive &&
                  <span><a className="history-link" href="/compare" onClick={this.compare}>Vergelijken</a> | </span>
                }
                {allowcompare && compareisactive &&
                  <span><a className="history-link" href="/compare" onClick={this.cancelCompare}>Stop vergelijken</a> | </span>
                }
                <a className="history-link" href={PATH_DOCUMENT + this.props.item.questId} onClick={this.select}>Bekijken</a>
              </div>
            }
          </div>
        )
      } else {
        return (
          <div className={"historyitem historyitem-" + (this.props.index % 2 === 0 ? "even" : "odd")}>
            <span>
              {viewing &&
                <FontAwesomeIcon className="history-eye" icon={faEye} title="In beeld" />
              }
            </span>

            <a className="history-link" href={PATH_DOCUMENT + this.props.item.questId} onClick={this.select}><h3>{this.props.item.title}</h3></a>

            <div className="history-subtitle">
              {this.props.item.metadata.getFirst("DublinCore", "title.subtitle")}
            </div>
            <div>
              <strong>Datum:</strong> <span className="history-pubdate">{formatDate(this.props.item.date)}</span>
            </div>

            {!viewing &&
              <div className="align-right">
                {allowcompare && !compareisactive &&
                  <span><a className="history-link" href="/compare" onClick={this.compare}>Vergelijken</a> | </span>
                }
                {allowcompare && compareisactive &&
                  <span><a className="history-link" href="/compare" onClick={this.cancelCompare}>Stop vergelijken</a> | </span>
                }
                <a className="history-link" href={PATH_DOCUMENT + this.props.item.questId} onClick={this.select}>Bekijken</a>
              </div>
            }
          </div>
        )
      }
		} else {
			return null;
		}
  }
}