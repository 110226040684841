import { observable, action, computed, autorun } from 'mobx';
import { QuestSearchApi, QuestQuery, QuestQueryResult, IQuestQueryHit } from './../api/quest';
import RootStore from './RootStore';
import { DUBLINCORETYPE_KWALITEITSVERKLARING } from './../constants';
import { getBrlLikePrefix } from './../utils/quest';
import * as _ from 'lodash';

export default class DocumentLookupKvStore {
  private _questSearchApi: QuestSearchApi;

  constructor(private _rootStore: RootStore) {
    this._questSearchApi = new QuestSearchApi();

    autorun(() => {
      this._loadKvList();
    });
  }

  @observable
  queryPage = 1;

  @observable
  queryResult = new QuestQueryResult<KomoKvHit>();

  @observable
  questId = "";

  @computed
  get moreHitsAvailable(): boolean {
    return this.queryResult.page * this.queryResult.pageSize < this.queryResult.total;
  }

  @action
  nextPage() {
    if (this.moreHitsAvailable) {
      this.queryPage = this.queryPage + 1;
    }
  }
  
  private _loadKvList = async () => {
    if ((this._rootStore.documentStore.document.questId !== this.questId) || (this.queryPage !== this.queryResult.page)) {
      if (this._rootStore.documentStore.document.questId !== this.questId) {
        this.questId = this._rootStore.documentStore.document.questId;
        this.queryPage = 1;
      }

      const prefix = getBrlLikePrefix(this._rootStore.documentStore.document.questId);
      if (prefix) {        
        const query = new QuestQuery({
          fields: "QuestId",
          returnFields: ["QuestId", "QuestTitle", "QuestDate", { field: "DublinCoretitlesubtitleNA", mapTo: "subTitle" }],
          systemQuery: `+QuestCurrent:1 +QuestOnline:1 +QuestIsDocument:1 -komostatus:Vervallen +Customapplication:komo +DublinCoretype:"${DUBLINCORETYPE_KWALITEITSVERKLARING}" +komokvbrllink:"${this._rootStore.documentStore.document.questId}"`,
          page: this.queryPage,
          pageSize: 25
        });

        const queryresult = await this._questSearchApi.query<KomoKvHit>(
          { licenseToken: this._rootStore.userStore.questContext.licenseToken, endpoint: this._rootStore.userStore.questContext.endpoint }, query);

        queryresult.hits = _.shuffle(queryresult.hits);

        if (this.queryResult.page + 1 === this.queryPage) {
          queryresult.hits = this.queryResult.hits.concat(queryresult.hits);
        }

        this.queryResult = queryresult;
      } else {
        this.queryResult = new QuestQueryResult<KomoKvHit>();
      }

      // Onderstaande code doet look up aan de hand van quest family ID, waar bovenstaande code kiest voor gebruik van een vooral voorspelde quest identificatie voor de brl. Waarschijnlijk werken beide oplossingen goed, 
      // maar onderstaande houdt beter rekening met de versie datum, echter daardoor krijg je soms minder resultaten door interpretatieverschillen in juist die datums.

      //const questfamilyid = this._rootStore.documentStore.document.metadata.getFirst("BRIS", "QuestFamilyId");      
      //const prefix = getBrlLikePrefix(questfamilyid);

      //if (questfamilyid && prefix) {        
      //  const brl = questfamilyid.substr(prefix.length);        
      //  const brldate = DateTime.fromISO(this._rootStore.documentStore.document.version.date).toSQLDate();              

      //  const query = new QuestQuery({
      //    fields: "QuestId",
      //    returnFields: ["QuestId", "QuestTitle", "QuestDate", { field: "DublinCoretitlesubtitleNA", mapTo: "subTitle" }],
      //    systemQuery: `+QuestCurrent:1 +QuestOnline:1 +QuestIsDocument:1 -komostatus:Vervallen +Customapplication:komo +DublinCoretype:\"${DUBLINCORETYPE_KWALITEITSVERKLARING}\" +komokvbrl:"${encodeURIComponent(brl)}" +komokvbrldate:"${brl}:${brldate}"`,
      //    page: this.queryPage,
      //    pageSize: 5
      //  });

      //  const queryresult = await this._questSearchApi.query<KomoKvHit>(
      //    { licenseToken: this._rootStore.userStore.questContext.licenseToken, endpoint: this._rootStore.userStore.questContext.endpoint }, query);

      //  if (this.queryResult.page + 1 == this.queryPage) {
      //    queryresult.hits = this.queryResult.hits.concat(queryresult.hits);
      //  }

      //  this.queryResult = queryresult;
      //} else {        
      //  this.queryResult = new QuestQueryResult<KomoKvHit>();
      //}
    }
  }
}

// Typed version of our hits, should match returnFields (but camelCase)
export interface KomoKvHit extends IQuestQueryHit {
  questId: string;

  questTitle: string;

  questDate: string;

  subTitle: string;
}