import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import RootStore from '../stores/RootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';

interface Props {
	rootStore?: RootStore
}

@inject("rootStore")

@observer
export default class ToggleFullScreen extends Component<Props> {
	toggle = () => {
		this.props.rootStore!.viewStore.toggleFullScreen();
	}

	render() {
		return (
			<Button className="btn-round right" size="sm" color="secondary" onClick={this.toggle} title={this.props.rootStore!.viewStore.fullScreen ? "Normaal scherm" : "Volledig scherm"}><FontAwesomeIcon
        icon={this.props.rootStore!.viewStore.fullScreen ? faWindowRestore : faWindowMaximize} /></Button>
		);
	}
}
