import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { formatDate } from '../../utils/formatters';
import CoverImage from './../CoverImage';
import './DocumentCover.css';

interface Props {
	rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class DocumentCover extends Component<Props> {
	render() {
		const d = this.props.rootStore!.documentStore.document;
		const v = d.version;

		let title = d.metadata.getFirst("DublinCore", "title.maintitle");
		if (!title) {
			title = d.versionTitle;
		}

		let subtitle = d.metadata.getFirst("DublinCore", "title.subtitle");
		if (!subtitle) {
			subtitle = d.metadata.getFirst("Custom", "tt_pubregel");
		}

		const desc = d.metadata.getFirst("Custom", "description");

		const vervangt = d.metadata.getFirst("Custom", "Vervangt");
		const type = d.metadata.getFirst("DublinCore", "type");

		const publisher = d.metadata.get("DublinCore", "publisher");
		const publisherdisplay = publisher.join(" en ");
		const subtype = d.metadata.get("Custom", "Subtype");
		const subtypedisplay = subtype.join(", ");

		return (
			<div id="divcontents" className="cover">
				<div className="cover-maininfo">
					<table><tbody><tr>
						<td className="maxwidth">
							<h2>{title}</h2>
							<h3>{subtitle}</h3>
						</td>
						<td className="minwidth">
							<CoverImage docType={type == null ? "" : type} />
						</td>
					</tr></tbody></table>
				</div>
				<div className="cover-metadata">
					<div className="cover-metadata-row">
						<div className="name">Publicatiedatum:</div>
						<div className="value">{formatDate(v.date)}</div>
					</div>
					{type && type.length > 0 &&
						<div className="cover-metadata-row">
							<div className="name">Keurmerk:</div>
							<div className="value">{type}</div>
						</div>
					}
					{subtype && subtype.length > 0 &&
						<div className="cover-metadata-row">
							<div className="name">Soort:</div>
							<div className="value">{subtypedisplay}</div>
						</div>
					}

					{vervangt && vervangt.length > 0 &&
						<div className="cover-metadata-row">
							<div className="name">Vervangt:</div>
							<div className="value">{vervangt}</div>
						</div>
					}									
					{desc && desc.length > 0 &&
						<div className="cover-metadata-row">
							<div className="name">Samenvatting:</div>
							<div className="value">{desc}</div>
						</div>
					}
				</div>

				{publisher && publisher.length > 0 &&
					<div className="cover-copyrights">
						<div className="name">Copyrights</div>
						<div className="value">
						© Copyright {publisherdisplay}. Alle rechten voorbehouden. Tenzij anders vermeld berusten alle rechten op informatie (tekst, beeld, geluid, video, etc) die u op deze site aantreft bij {publisherdisplay}.<br /><br />
							Gehele of gedeeltelijke overname, plaatsing op andere sites, verveelvoudiging op welke andere wijze dan ook en/of commercieel gebruik van deze informatie is niet toegestaan, tenzij hiervoor
					uitdrukkelijk schriftelijke toestemming is verleend door Imeco of tenzij aan de onderstaande voorwaarden wordt voldaan.<br /><br />
							Deze informatie mag worden bekeken op een scherm, gedownload worden op een hard-disk of geprint worden, mits dit geschied voor persoonlijk, informatief en niet-commercieel gebruik, mits de
					informatie niet gewijzigd wordt, mits de volgende copyright-tekst in elke copy aanwezig is: “© Copyright {publisherdisplay}”, mits copyright, handelsmerk en andere van toepassing zijnde teksten niet worden
					verwijderd en mits de informatie niet wordt gebruikt in een ander werk of publicatie in welk medium dan ook.<br /><br />
						{publisherdisplay} voert het beheer over de pagina’s op deze website. Het linken en verwijzen naar de pagina’s op deze website is toegestaan.
					</div>
				</div>
				}

			</div>
		);
	}
}