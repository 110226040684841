import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import NavigateTo from './NavigateTo';
import RootStore from './../stores/RootStore';
import { Row, Col } from 'reactstrap';
import { PATH_SEARCH, PATH_DOCUMENT, VIEW_SEARCH, VIEW_DOCUMENT } from './../constants';
import './Navigation.css';
import KennisIDStatus from './kennisid/KennisIDStatus';

interface Props {
  rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class Navigation extends Component<Props> {
  render() {
    return (
      <Row>
				<Col className="mainmenu nopadding">
          <NavigateTo text="Zoeken" description="Selectie publicatie" enabled={true} path={PATH_SEARCH} viewId={VIEW_SEARCH} />
          <NavigateTo text="Document" description="Door u geselecteerd" enabled={this.props.rootStore!.viewStore.hasQuestId} path={PATH_DOCUMENT} viewId={VIEW_DOCUMENT} />
          <KennisIDStatus kennisIDInfo={this.props.rootStore!.userStore.kennisID} />
        </Col>
      </Row >
    )
  }
}