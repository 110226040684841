import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { questUrl, hasInternalPrefix } from './../../utils/quest';
import './DocumentHtml.css';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")

@observer
export default class DocumentHtml extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this._contentRef = React.createRef();
  }

  private _observer = new MutationObserver(() => {    
    this._resolveAll();
  });
  private _contentRef: React.RefObject<HTMLDivElement>;

  componentDidMount() {    
    this._resolveAll();

    this._observer.observe(this._contentRef.current as Node, {
      // Check config in https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
      childList: true
    });    
  }

  componentWillUnmount() {    
    this._observer.disconnect();
  }

  render() {
    return (
      <div id="divcontents" className="documenthtml" ref={this._contentRef} dangerouslySetInnerHTML={this._createMarkup(this.props.rootStore!.documentStore.htmlData)}></div>
    )
  }

  private _createMarkup(html: string) {
    return { __html: html };
  }

  private _resolveAll() {
    this._resolveImages();
    this._resolveLinkManager();
  }

  private _resolveImages() {    
    if (this._contentRef.current) {
      const imglist = (this._contentRef.current as HTMLElement).getElementsByTagName("img");

      for (let index = 0; index < imglist.length; index++) {
        const img: HTMLImageElement = imglist.item(index) as HTMLImageElement;
        const src = img.getAttribute("data-original");
        if (src) {
          img.setAttribute("src", `${this.props.rootStore!.userStore.questContext.endpoint}/api/document/data/${this.props.rootStore!.documentStore.rootQuestId}/${src}?MagmaLicenseToken=${this.props.rootStore!.userStore.questContext.licenseToken}`);
        }
      }
    }
  }

  private _resolveLinkManager() {
    if (this._contentRef.current) {
      const lmlist = (this._contentRef.current as HTMLElement).getElementsByTagName("a");

      for (let index = 0; index < lmlist.length; index++) {
        const a: HTMLAnchorElement = lmlist.item(index) as HTMLAnchorElement;

        const countvalue = a.getAttribute("data-lmtargetcount");
        if (countvalue) {
          const count = parseInt(countvalue);

          const directurl = a.getAttribute("data-lmdirecturl");
          const directquest = a.getAttribute("data-lmdirectquest");

          let url: string | null = null;
          let target: string | null = null;

          if (directurl) {
            url = directurl;
          } else if (directquest) {
            if (!hasInternalPrefix(directquest)) {
              target = "komo";
            }
            url = questUrl(directquest, this.props.rootStore!.userStore!.kennisID.loggedIn);
          }

          if (url) {
            a.setAttribute("href", url);
            if (target) {
              a.setAttribute("target", target);
            }
          }

          if (count > 1) {
            const code = a.getAttribute("data-lmcode");
            if (code) {
              a.addEventListener("click", (e: MouseEvent) => {
                const a: HTMLAnchorElement = (e.target as HTMLAnchorElement);
                const code = a.getAttribute("data-lmcode");
                if (code) {
                  this.props.rootStore!.linkManagerStore.setCode(code);
                }
                e.preventDefault();
              });
            }
          }
        }
      }
    }
  }
}