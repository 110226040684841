import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import QueryDocument from './QueryDocument';
import QueryResultList from './QueryResultList';
import Toc from './Toc';
import { PATH_DOCUMENT } from './../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

interface Props {
  rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class Navigation extends Component<Props> {
  select = (e: React.MouseEvent) => {
    if (!e.ctrlKey) {
      this.props.rootStore!.viewStore.setQuestId(this.props.rootStore!.documentStore.rootQuestId);

      e.preventDefault();
    }
  }

  closeQueryResults = (e: React.MouseEvent) => {    
    this.props.rootStore!.documentSearchStore.setShowQueryResult(false);
  }

  render() {
    return (
			<div className="borderbox scrollxauto">
        <QueryDocument />
        {this.props.rootStore!.documentSearchStore.showQueryResult &&
					<Fragment>
					<Button onClick={this.closeQueryResults}><FontAwesomeIcon icon={faChevronCircleLeft}/> Terug naar inhoudsopgave</Button><br /><br />
            <QueryResultList />
				</Fragment>
        }

        {!this.props.rootStore!.documentSearchStore.showQueryResult &&
					<Fragment>
					<div className={"toc-item-area" +(this.props.rootStore!.documentStore.rootQuestId === this.props.rootStore!.viewStore.questId ? " selected": "")}>
						<a href={`${PATH_DOCUMENT}${this.props.rootStore!.documentStore.rootQuestId}`} onClick={this.select} className="toc-item">Titelpagina</a>
					</div>
            <Toc tocNodes={this.props.rootStore!.documentStore.tocNodes} rootStore={this.props.rootStore!} />
          </Fragment>
        }
      </div>
    );
  }
}