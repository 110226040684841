import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import './ResultList.css';

interface Props {
	rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class ResetQuery extends Component<Props> {
	reset = (e: React.MouseEvent) => {
		this.props.rootStore!.searchStore.resetFilter();
		e.preventDefault();
	}

	render() {
    if (this.props.rootStore!.searchStore.queryFilterCustomized) {
			return <p><a className="reset-query" href="#" onClick={this.reset}>Herstel filter</a></p>
		} else {
			return <p>&nbsp;</p>
		}
	}
}