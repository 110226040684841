import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormGroup, Label } from 'reactstrap';
import { QuestFacet } from './../../api/quest';
import FacetHit from './FacetHit';

interface Props {
  facet: QuestFacet;
  index: number;
}

@observer
export default class Facet extends Component<Props> {
  render() {
    return (
      <FormGroup>
        <Label className="group-title">{this.props.facet.name}</Label>
        <div className="group-area">
          {this.props.facet.hits.map((hit) => <FacetHit hit={hit} key={hit.value} />)}          
        </div>
      </FormGroup>
    )
  }
}