import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { QuestDocument } from './../../api/quest';
import { PATH_DOCUMENT } from '../../constants';
import CoverImage from './../CoverImage';
import RootStore from './../../stores/RootStore';
import { DUBLINCORETYPE_BEOORDELINGSRICHTLIJN } from './../../constants';

interface Props {
  rootStore?: RootStore;
  brlDocument: QuestDocument;  
}

@inject("rootStore")
@observer
export default class KvBrlLinkList extends Component<Props> {
  select = (e: React.MouseEvent) => {
    if (!e.ctrlKey) {
      this.props.rootStore!.viewStore.setQuestId(this.props.brlDocument.version.questId);

      e.preventDefault();
    }
  }

	render() {
		const subtitle = this.props.brlDocument.metadata.getFirst("DublinCore", "title.subtitle");

		return (
			<div className="kvbrllinkitem">
				<table className="maxwidth">
					<tbody>
						<tr>
							<td className="minwidth valign-top">
								<CoverImage docType={DUBLINCORETYPE_BEOORDELINGSRICHTLIJN} />
							</td>
							<td className="maxwith valign-top">
                <a href={PATH_DOCUMENT + this.props.brlDocument.version.questId} onClick={this.select}><h3>{this.props.brlDocument.versionTitle}</h3></a>
								<div className="subtitle">{subtitle}</div>
                <div>
                  <a className="kvbrllinkitem-link" href={PATH_DOCUMENT + this.props.brlDocument.version.questId} onClick={this.select}>Bekijken</a>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}