import { observable, action, computed } from 'mobx';
import { QuestContext } from './../api/quest';
import { DateTime } from 'luxon';
import axios from 'axios';
import RootStore from './RootStore';
import { KENNISIDURL } from './../constants';
import { getKennisIDRedirectUrl, KennisIDInfo } from './../utils/kennisid';

export default class UserStore {
  constructor(private _rootStore: RootStore) {
    this._updateContext();
  }

  @observable
  questContext: QuestContext = {
    endpoint: "",
    licenseToken: ""
  };

  @observable
  kennisIDInitialized = false;

  @observable
  kennisID: KennisIDInfo = new KennisIDInfo();

  @action
  private async _updateContext() {    
    const context = await axios("/api/user/context");
    const { data, status } = context;

    let timeout = 5000;

    if ((status === 200) && (this.questContext.licenseToken !== data.licenseToken)) {
      if (window.navigator.userAgent.indexOf('Googlebot') > 0) {
        await this._updateKennisIDInfo("?");
      } else {
        if (!data.kennisIDToken) {
          window.location.href = getKennisIDRedirectUrl();
          return;
        } else {
          await this._updateKennisIDInfo(data.kennisIDToken);
        }
      }

      this.questContext = {
        endpoint: data.questEndpoint,
        licenseToken: data.licenseToken
      };

      const expires = DateTime.fromISO(data.expires).minus({ minutes: 5 });
      const diff = expires.diffNow("milliseconds").toObject();

      timeout = diff.milliseconds ? diff.milliseconds : 5000;
    }

    window.setTimeout(() => {
      this._updateContext();
    }, timeout);
  }

  @computed get isReady() {
    return this.questContext.licenseToken !== "" && this.questContext.endpoint !== "" && this.kennisIDInitialized;
  }

  private async _updateKennisIDInfo(token: string) {
    const i = new KennisIDInfo();
    
    if (token !== "?") {
      const info = await axios(`${KENNISIDURL}/api/authentication/passportinfobytoken?token=${encodeURIComponent(token)}`);
    
      if (info.status === 200 && info.data != null) {
        i.email = info.data.PassportEmail;
        i.name = info.data.PassportDisplayFullName;
        i.organisationName = info.data.OrganisationName;
        i.uniqueId = info.data.PassportUniqueId;
        i.loggedIn = true;
      }
    }

    this.kennisID = i;
    this.kennisIDInitialized = true;
  }
}