import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import KvLookupItem from './KvLookupItem';
import RootStore from '../../stores/RootStore';
import './KvLookupList.css';

interface Props {
	rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class KvLookupList extends Component<Props> {
	more = (e: React.MouseEvent) => {
		this.props.rootStore!.documentLookupKvStore.nextPage();
		e.preventDefault();
	}

	render() {
		if (this.props.rootStore!.documentLookupKvStore.queryResult!.hits.length > 0) {
			return (
				<div className="kvlookuplist borderbox scrollxauto">
          <h2>Kwaliteitsverklaringen</h2>
          <div className="kvlookuplist-statistics"><strong>{this.props.rootStore!.documentLookupKvStore.queryResult!.total}</strong> {this.props.rootStore!.documentLookupKvStore.queryResult!.total === 1 ? "resultaat" : "resultaten"}</div>					

					{this.props.rootStore!.documentLookupKvStore.queryResult!.hits.map((kvlookupitem, index) => <KvLookupItem kvlookupitem={kvlookupitem} index={index} key={kvlookupitem.questId} />)}

					{this.props.rootStore!.documentLookupKvStore.moreHitsAvailable &&
						<div><br /><Button color="primary" onClick={this.more}>Meer laden...</Button></div>
					}
				</div>
			);
		} else {
			return "";
		}
	}
}