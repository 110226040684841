import { observable, autorun } from 'mobx';
import { QuestSearchApi, QuestQuery, QuestQueryResult, IQuestQueryHit } from './../api/quest';
import RootStore from './RootStore';

export default class RecentStore {
  private _questSearchApi: QuestSearchApi;

  constructor(private _rootStore: RootStore) {
    this._questSearchApi = new QuestSearchApi();

    autorun(() => {
      this._runQuery();
    });
  }

  @observable
  queryResult = new QuestQueryResult<KomoRecentHit>();

  @observable
  initialQueryExecuted = false;

  private _runQuery = async () => {
    if (this._rootStore.userStore.isReady && !this.initialQueryExecuted) {
      this.initialQueryExecuted = true;

      const query = new QuestQuery({
        fields: "QuestId",
				returnFields: ["QuestId", "QuestTitle", "QuestDate", "DublinCoretype", { field: "DublinCoretitlesubtitleNA", mapTo: "subTitle" }],
        systemQuery: "+QuestCurrent:1 +QuestOnline:1 +QuestIsDocument:1 +Customapplication:komo",
        page: 1,
        pageSize: 5,
        sort: "QuestDate:true"
      });

      const queryresult = await this._questSearchApi.query<KomoRecentHit>(
        { licenseToken: this._rootStore.userStore.questContext.licenseToken, endpoint: this._rootStore.userStore.questContext.endpoint }, query);

      this.queryResult = queryresult;
    }
  }
}

// Typed version of our hits, should match returnFields (but camelCase)
export interface KomoRecentHit extends IQuestQueryHit {
  questId: string;

	questTitle: string;

	subTitle: string;

	questDate: string;

	dublinCoretype: string;
}