import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import OsfLookupItem from './OsfLookupItem';
import RootStore from '../../stores/RootStore';
import './OsfLookupList.css';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class OsfLookupList extends Component<Props> {
  more = (e: React.MouseEvent) => {
    this.props.rootStore!.documentLookupOsfStore.nextPage();
    e.preventDefault();
  }

  render() {
    if (this.props.rootStore!.documentLookupOsfStore.queryResult!.hits.length > 0) {
      return (
				<div className="osflookuplist borderbox scrollxauto">
          <h2>Bestekspecificaties</h2>
          <div className="osflookuplist-statistics"><strong>{this.props.rootStore!.documentLookupOsfStore.queryResult!.total}</strong> {this.props.rootStore!.documentLookupOsfStore.queryResult!.total === 1 ? "resultaat" : "resultaten"}</div>

          {this.props.rootStore!.documentLookupOsfStore.queryResult!.hits.map((osflookupitem, index) => <OsfLookupItem osflookupitem={osflookupitem} index={index} key={osflookupitem.questId} />)}

          {this.props.rootStore!.documentLookupOsfStore.moreHitsAvailable &&
            <div><br /><Button color="primary" onClick={this.more}>Meer laden...</Button></div>
          }
        </div>
      );
    } else {
      return "";
    }
  }
}