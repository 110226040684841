import { pickForTarget } from './utils';
import { parseQuestId } from './identification';
import { QuestMetadata } from './types-metadata';

export class QuestInfoOptions {
  includeMetadata?= true;

  includeCollections?= false;

  includeParents?= false;

  includeAlternatives?= false;

  includeAlternativesMetaData?= false;

  includeImportLog?= false;

  includeLicenseItem?= false;

  claimLicenseItem?= false;

  allowCachedData?= true;

  allowInvalid?= false;
}

export class QuestDataOptions {
  compose?= true;

  transform?= "";

  linkManager?= false;

  allowOffline?= false;
}

export class QuestDocument {
  fromServer(data: any) {
    pickForTarget(data.Document, this);
    this.version.fromServer(data);
    this.versionTitle = (this.version.isSet && this.version.title && this.version.title !== "") ? this.version.title : this.title;
    this.metadata.fromServer(data);
    this.license = (data.LicenseItem && data.LicenseItem.AccessStatus === "Enabled");
  }

  questId = "";

  title = "";

  version = new QuestDocumentVersion(this);

  // Consolidated title of version associated with this document or this document in case no version was associated.
  versionTitle = "";

  metadata = new QuestMetadata();

  license = false;
}

export class QuestDocumentVersion {
  constructor(document: QuestDocument) {
    this.document = document;
  }

  fromServer(data: any) {
    pickForTarget(data.Version, this);
    this.node.fromServer(data);

    this.isSet = this.version > 0;
    if (this.isSet) {
      this.questId = parseQuestId(this.document.questId).add(this.version).toString(true);
    }
  }

  questId = "";

  isSet = false;

  version = 0;

  valid = false;

  date = "";

  current = false;

  description = "";

  importerId = "";

  hasNodes = false;

  title?: string;

  node = new QuestDocumentNode(this);

  document: QuestDocument;
}

export class QuestDocumentNode {
  constructor(version: QuestDocumentVersion) {
    this.version = version;
  }

  fromServer(data: any) {
    pickForTarget(data.CurrentNode, this, "questId");

    this.nodeId = data.CurrentNode.QuestId;

    this.isSet = this.nodeId != null && this.nodeId !== "";
    if (this.isSet) {
      this.questId = parseQuestId(this.version.document.questId).add(this.nodeId).add(this.version.version).toString(true);
      this.url = data.Version.Url;
    }
  }

  questId = "";

  isSet = false;

  nodeId = "";

  composable = false;

  childCount = 0;

  hasChilds = false;

  mimeType = "";

  title = "";

  type = "";

  // Url to Quest data API for node contents
  url = "";

  version: QuestDocumentVersion;
}

export class QuestTocNode {
  questId = "";

  nodeId = "";

  title = "";

  composable = false;

  childCount = 0;

  hasChilds = false;

  mimeType = ""

  opened = false;

  childs?: Array<QuestTocNode>;
}