export class LinkManagerTarget {
  default: boolean = false;

  sequence: number = 0;

  type: string = "";

  quest = new LinkManagerQuestTarget();

  fromServer(data: any) {
    this.default = data.Default;
    this.sequence = data.Sequence;
    this.type = data.Type;

    if (this.type === "Quest") {
      this.quest.fromServer(data.Quest);
    }
  }
}

export class LinkManagerQuestTarget {
  label: string = "";

  url: string = "";

  id: string = "";

  text: string = "";

  title: string = "";

  fromServer(data: any) {
    this.label = data.Label;
    this.url = data.Url;
    this.id = data.Id;
    this.text = data.Text;
    this.title = data.Title;

  }
}