import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { DUBLINCORETYPE_BESTEKSPECIFICATIE} from './../../constants';
import './DownloadKomoCatalogus.css';

interface Props {
	docType: string;
}

export default class DownloadKomoCatalogus extends Component<Props> {
	render() {
    if (this.props.docType === DUBLINCORETYPE_BESTEKSPECIFICATIE) {
			return <div className="borderbox downloadkomocatalogus">
				<a className="btn btn-primary btn-lg" href="http://komo.openspecificaties.nl/Viewer/DownloadOSF.aspx" target="komo">
					<FontAwesomeIcon icon={faFileDownload} size="lg" /> <strong>Download<br />KOMO Catalogus</strong>
				</a>
			</div>
		} else {
			return ""
		}
	}
}