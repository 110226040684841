import React, { Component } from 'react';
import { observable, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Form, Button, Input } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class QueryDocument extends Component<Props> {
  constructor(props: Props) {
    super(props);

    reaction(() => this.props.rootStore!.documentSearchStore.queryText, (text, reaction) => {
      if (this.queryText !== text) {
        this.queryText = text;
      }
    });
  }

  @observable
  queryText: string = this.props.rootStore!.documentSearchStore.queryText;

  handleChange = (e: any) => {
    this.queryText = e.target.value;
  };

  handleSubmit = (e: any) => {
    this.props.rootStore!.documentSearchStore.setQueryText(this.queryText);
    this.props.rootStore!.documentSearchStore.setShowQueryResult(true);

    e.preventDefault();
  };

  deleteSearchQuery = (e: any) => {
    this.queryText = "";
    this.props.rootStore!.documentSearchStore.setShowQueryResult(false);
  };

  render() {
    return (
			<Form inline onSubmit={this.handleSubmit}>
				<table className="inputquery-area"><tbody><tr>
          <td className="inputquery-textarea"><Input className="inputquery-text" type="text" value={this.queryText} onChange={this.handleChange} placeholder={`Zoek in ${this.props.rootStore!.documentStore.document.versionTitle}`} /><span className="deletesearchquery" onClick={this.deleteSearchQuery} title="Herstel zoekopdracht"><FontAwesomeIcon icon={faTimes} /></span></td>
					<td className="inputquery-buttonarea"><Button className="inputquery-button small" type="submit" disabled={this.queryText === ""}><FontAwesomeIcon icon={faSearch} /></Button></td>
				</tr></tbody></table>
      </Form>
    )
  }
}