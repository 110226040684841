import UniversalRouter, { Context } from 'universal-router';
import { autorun } from 'mobx';
import RootStore from './../stores/RootStore';
import { VIEW_SEARCH, VIEW_DOCUMENT } from './../constants';

export function startRouter(rootStore: RootStore) {
  const routes = [
    {
      path: "/",
      action: () => {
        return { viewId: VIEW_SEARCH, questId: null };
      }
    },
    {
      path: "/:questId*",
      action: (context: Context) => {
        return { viewId: VIEW_DOCUMENT, questId: context.params.questId.join("/") };
      }
    }
  ];

  const router = new UniversalRouter(routes);

  router.resolve(window.location.pathname).then((info: any) => {
    _updateState(info);

    autorun(() => {
      const path = rootStore.viewStore.currentPath;
      
      if (path !== window.location.pathname) {
        window.history.pushState(null, rootStore.viewStore.currentTitle, path);
      }
    });

    window.addEventListener("popstate", (e: PopStateEvent) => {
      router.resolve(window.location.pathname).then((info: any) => {
        _updateState(info);
      });
    });
  });

  function _updateState(info: any) {
    rootStore.viewStore.setViewId(info.viewId);
    if (info.questId) {      
      rootStore.viewStore.setQuestId(info.questId);
    }
  }
}