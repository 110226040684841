export const BRLPREFIX = "brl/brl";
export const URLPREFIX = "url/url";
export const PBLPREFIX = "pbl/pbl";

export const OSFPREFIX = "osf/";
export const KVPREFIX = "kv/";

export const DUBLINCORETYPE_BEOORDELINGSRICHTLIJN = "Beoordelingsrichtlijn";
export const DUBLINCORETYPE_UITVOERINGSRICHTLIJN = "Uitvoeringsrichtlijn";
export const DUBLINCORETYPE_KWALITEITSVERKLARING = "Kwaliteitsverklaring";
export const DUBLINCORETYPE_BESTEKSPECIFICATIE = "Bestekspecificatie";