import { QuestFacetDefinition, QuestFacetHit, QuestFacetSortOrder, QuestFacetSortField, QuestFacet, QuestFacetList } from './types-facet';

export function facetNameForValue(value: string, facetDefinition?: QuestFacetDefinition): string {
  if (value && facetDefinition && facetDefinition.rename) {
    if (typeof facetDefinition.rename == "object") {
      const n = facetDefinition.rename[value];
      return n || value;
    } else {
      return facetDefinition.rename(value);
    }
  } else {
    return value;
  }
}

export function filterFacets(facetList: QuestFacetList): Array<QuestFacet> {
  if (facetList.definitionList) {
    const includelist = new Array<string>();

    facetList.definitionList.forEach((definition) => {
      if (!definition.show || definition.show(facetList)) {
        includelist.push(definition.term);
      }
    });

    return facetList.list.filter((facet) => {
      return includelist.includes(facet.term);
    });
  } else {
    return facetList.list;
  }
}

export function filterFacetHits(hits: Array<QuestFacetHit>, facetDefinition?: QuestFacetDefinition): Array<QuestFacetHit> {
  if (facetDefinition && facetDefinition.include) {
    return hits.filter((hit) => {
      if (typeof facetDefinition.include == "function") {
        return facetDefinition.include(hit.value);
      } else if (facetDefinition.include !== undefined) {
        return facetDefinition.include.includes(hit.value);
      } else {
        return true;
      }
    });
  }

  return hits;
}

export function sortFacetHits(hits: Array<QuestFacetHit>, facetDefinition?: QuestFacetDefinition): Array<QuestFacetHit> {
  if (facetDefinition) {
    if (facetDefinition.sort) {
      return hits.sort(facetDefinition.sort);
    } else if (facetDefinition.sortField !== undefined) {
      hits = hits.sort((a: QuestFacetHit, b: QuestFacetHit): number => {
        switch (facetDefinition.sortField) {
          case QuestFacetSortField.Count:
            if (a.count < b.count) {
              return -1;
            } else if (a.count > b.count) {
              return 1;
            } else {
              return 0;
            }
          case QuestFacetSortField.Name:
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            } else {
              return 0;
            }
          case QuestFacetSortField.Value:
            if (a.value.toLowerCase() < b.value.toLowerCase()) {
              return -1;
            } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
              return 1;
            } else {
              return 0;
            }
        }

        return 0;
      });

      if (facetDefinition.sortOrder === QuestFacetSortOrder.Descending) {
        return hits.reverse();
      }
    }
  }

  return hits;
}