import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RootStore from '../../stores/RootStore';
import { PATH_DOCUMENT } from '../../constants';
import { KomoOsfHit } from '../../stores/DocumentLookupOsfStore';
import CoverImage from './../CoverImage';
import { DUBLINCORETYPE_BESTEKSPECIFICATIE } from './../../constants';

interface Props {
  rootStore?: RootStore;
  osflookupitem: KomoOsfHit;
  index: number;
}

@inject("rootStore")
@observer
export default class OsfLookupItem extends Component<Props> {
  select = (e: React.MouseEvent) => {
    if (!e.ctrlKey) {
      this.props.rootStore!.viewStore.setQuestId(this.props.osflookupitem.questId);

      e.preventDefault();
    }
  }

  render() {
    return (
      <div className="osflookupitem">
        <table className="maxwidth">
          <tbody>
            <tr>
              <td className="minwidth valign-top">
                <CoverImage docType={DUBLINCORETYPE_BESTEKSPECIFICATIE} />
              </td>
              <td className="maxwith valign-top">
                <a className="osflookupitem-link" href={PATH_DOCUMENT + this.props.osflookupitem.questId} onClick={this.select}><h3>{this.props.osflookupitem.questTitle}</h3></a>
                <div className="subtitle">{this.props.osflookupitem.subTitle}</div>
                {this.props.osflookupitem.license &&
                  <div>
                    <a className="osflookupitem-link" href={PATH_DOCUMENT + this.props.osflookupitem.questId} onClick={this.select}>Bekijken</a>
                  </div>
                }
                {!this.props.osflookupitem.license &&
                  <div>geen licentie</div>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}