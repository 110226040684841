import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Recent from './Recent';
import RootStore from '../../stores/RootStore';
import './RecentList.css';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class RecentList extends Component<Props> {
  render() {
    return (
			<div className="recentlist borderbox scrollxauto">   
        <h2>Recent gewijzigd</h2>
        {this.props.rootStore!.recentStore.queryResult!.hits.map((recent, index) => <Recent recent={recent} index={index} key={recent.questId} />)}
      </div>
    )
  }
}