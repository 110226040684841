import * as _ from 'lodash';
import { QuestContext } from './types';

export function toQueryString(values: object): string {
  return Object.entries(values)
    .filter(([key, value]) => { return (key && (value || value === false)) })
    .map(([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value.toString())).join("&");
}

export function toCamelCase(source: any): any {
  return _.mapKeys(source, (v, k) => _.camelCase(k));
}

// Set values from source to target but only if they already exist as property on target
export function pickForTarget(source: object, target: object, ...exclude: string[]) {
  const keys = _.difference(_.keys(target), exclude);  
  const data = _.pick(toCamelCase(source), keys);
  Object.assign(target, data);
}

export function pickForTargetTyped<T>(source: object, target: T, ...exclude: string[]) {
  const keys = _.difference(_.keys(target), exclude);
  const data = _.pick(toCamelCase(source), keys);
  Object.assign(target, data);
}

export function getRequestOptions(context: QuestContext, contentType?: string) {
  return {
    headers: {
      "Content-Type": contentType ? contentType : "application/json",
      "MagmaLicenseToken": context.licenseToken
    }
  };
}