import axios from 'axios';
import { QuestContext } from './types';
import { QuestQuery, QuestQueryResult, IQuestQueryHit } from './types-search';
import { toQueryString, getRequestOptions } from './utils';

export class QuestSearchApi {
  async query<T extends IQuestQueryHit>(context: QuestContext, query: QuestQuery): Promise<QuestQueryResult<T>> {
    const qr = new QuestQueryResult<T>();

    query.flatten(qr);

    return await axios.post(`${context.endpoint}/api/search/query`, toQueryString(query),
      getRequestOptions(context, "application/x-www-form-urlencoded")).then((response) => {
        if (response.status === 200) {
          qr.fromServer(response.data);
        }

        return qr;
      });
  }
}