import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

interface Props {
  rootStore?: RootStore;
}

@inject("rootStore")
@observer
export default class Share extends Component<Props> {
  toggle = () => {
    this.props.rootStore!.documentStore.setShareOpen(!this.props.rootStore!.documentStore.shareOpen);
  }

  render() {
    return (
      <Fragment>
				<Button className="btn-round hidden" size="sm" color="secondary" onClick={this.toggle}><FontAwesomeIcon icon={faShare} /> Delen</Button>
        <Modal isOpen={this.props.rootStore!.documentStore.shareOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Delen</ModalHeader>
          <ModalBody>
            <p>Hier komt de delen functionaliteit!</p>
          </ModalBody>
        </Modal>
			</Fragment>
    );
  }
}