import { pickForTarget } from './utils';
import { parseQuestId } from './identification';
import { QuestMetadata } from './types-metadata';

export enum QuestVersionMapItemType { Predecessor, Revision, Successor };

export class QuestVersionMapOptions {
  allowOffline?= false;

  includeMetadata?= false;

  includeCollections?= false;

  includeCollectionMetadata?= false;

  includePredecessors?= true;

  includeSuccessors?= true;

  includePredSuccVersions?= false;

  maxLevel?= 10;
}

export class QuestVersionMap {
  fromServer(data: any) {
    if (data) {
      data.forEach((itemData: any) => {
        const item = new QuestVersionMapItem();
        item.fromServer(itemData);

        this.list.push(item);
      });
    }
  }

  questId = "";

  list = new Array<QuestVersionMapItem>();
}

export class QuestVersionMapItem {
  fromServer(data: any) {
    pickForTarget(data, this, "type");
    this.metadata.fromServer(data);

    this.type = data.Type === "Predecessor" ? QuestVersionMapItemType.Predecessor :
      data.Type === "Successor" ? QuestVersionMapItemType.Successor :
        QuestVersionMapItemType.Revision;
    this.questId = parseQuestId(this.questId).add(this.version).toString(true);
  }

  type: QuestVersionMapItemType = QuestVersionMapItemType.Revision;

  version = 1;

  questId = "";

  title = "";

  description = "";

  date = "";

  current = false;

  online = false;

  metadata = new QuestMetadata();
}