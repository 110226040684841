import * as _ from 'lodash';

export class QuestIdentification {
  private _questId: string;
  version?: number;

  constructor(questId: string) {
    this._questId = "";
    this.parse(questId);
  }

  add(part: string | number): QuestIdentification {
    if (typeof part == "string") {
      const p = this._trim(part);
      if (p !== "") {
        this._questId += "/" + p;
      }
    } else if (typeof part == "number") {
      this.version = part;
    }

    return this;
  }

  toString(includeVersion: boolean): string {
    if (includeVersion && typeof this.version == "number") {
      return this._questId + `[${this.version}]`;
    } else {
      return this._questId;
    }
  }

  parse(questId: string) {
    this.version = undefined;

    const start = questId.lastIndexOf("[");
    if (start > 0) {
      const end = questId.indexOf("]", start);
      if (end > start) {
        this.version = parseInt(questId.substr(0, start) + questId.substring(end + 1));
        questId = questId.substr(0, start) + questId.substr(end + 1);
      }
    }

    this._questId = this._trim(questId);
  }

  private _trim(part: string): string {
    return _.trimEnd(part, '/ ');
  }
}

export function parseQuestId(questId: string) {
  return new QuestIdentification(questId);
}