import React, { Component } from 'react';
import { observable, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Form, Button, Input } from 'reactstrap';
import RootStore from '../../stores/RootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
  rootStore?: RootStore
}

@inject("rootStore")
@observer
export default class Query extends Component<Props> {
  constructor(props: Props) {
    super(props);

    reaction(() => this.props.rootStore!.searchStore.queryText, (text, reaction) => {
      if (this.queryText !== text) {
        this.queryText = text;
      }
    });
  }

  @observable
  queryText: string = this.props.rootStore!.searchStore!.queryText;

  handleChange = (e: any) => {
    this.queryText = e.target.value;
  };

  handleSubmit = (e: any) => {
    this.props.rootStore!.searchStore.setQueryText(this.queryText);

    e.preventDefault();
  };

  deleteSearchQuery = (e: any) => {
    this.queryText = "";
    this.props.rootStore!.searchStore.setQueryText("");
  };

  render() {
    return (
			<Form inline onSubmit={this.handleSubmit}>
        <table className="inputquery-area"><tbody><tr>
          <td className="inputquery-textarea"><Input className="inputquery-text" type="text" value={this.queryText} onChange={this.handleChange} placeholder="Zoekterm..." /><span className="deletesearchquery" onClick={this.deleteSearchQuery} title="Herstel zoekopdracht"><FontAwesomeIcon icon={faTimes} /></span></td>
          <td className="inputquery-buttonarea"><Button className="inputquery-button wide" color="primary" type="submit" disabled={this.queryText === "" && this.queryText === this.props.rootStore!.searchStore.queryText}><FontAwesomeIcon icon={faSearch} /> Zoeken</Button></td>
				</tr></tbody></table>
      </Form>
    )
  }
}