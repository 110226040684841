import { observable, computed, action, reaction } from 'mobx';
import RootStore from './RootStore';
import { QuestSearchApi, IQuestQueryHit, QuestQuery, QuestQueryResult } from '../api/quest';

export default class DocumentSearchStore {
  private _questSearchApi: QuestSearchApi;

  constructor(private _rootStore: RootStore) {
    this._questSearchApi = new QuestSearchApi();

    reaction(() => {
      return [this.queryText, this.queryPage]
    }, (text, reaction) => {
      this._runQuery();
    });

    reaction(() => this._rootStore.documentStore.rootQuestId, (text, reaction) => {
      this.resetQuery();
    });
  }

  @observable
  queryText: string = "";

  @observable
  queryPage = 1;

  @observable
  queryResult = new QuestQueryResult<KomoDocumentNodeHit>();

  @computed
  get moreHitsAvailable(): boolean {
    return this.queryResult.page * this.queryResult.pageSize < this.queryResult.total;
  }

  @observable
  showQueryResult = false;

  @action
  setQueryText(queryText: string) {
    this.queryText = queryText;
  }

  @action
  setShowQueryResult(value: boolean) {
    this.showQueryResult = value;
  }

  @action
  resetQuery() {
    this.queryText = "";
    this.queryPage = 1;
    this.queryResult = new QuestQueryResult<KomoDocumentNodeHit>();
    this.showQueryResult = false;
  }

  @action
  nextPage() {
    if (this.moreHitsAvailable) {
      this.queryPage = this.queryPage + 1;
    }
  }

  private _runQuery = async () => {
    if (this._rootStore.userStore.isReady && this._rootStore.documentStore.isReady && this.queryText !== "") {
      const query = new QuestQuery({
        query: this.queryText,
        fields: ["QuestId", "QuestTitle", "Contents"],
        returnFields: ["QuestId", "QuestTitle", "Contents*"],
        systemQuery: `+QuestCurrent:1 +QuestOnline:1 +Customapplication:komo +QuestDocumentId:"${this._rootStore.documentStore.document.questId}" +QuestVersion:${this._rootStore.documentStore.document.version.version}`,
        page: this.queryPage,
        pageSize: 10
      });

      const queryresult = await this._questSearchApi.query<KomoDocumentNodeHit>(
        { licenseToken: this._rootStore.userStore.questContext.licenseToken, endpoint: this._rootStore.userStore.questContext.endpoint }, query);

      if (this.queryResult.page + 1 === this.queryPage) {
        queryresult.hits = this.queryResult.hits.concat(queryresult.hits);
      }

      this.queryResult = queryresult;
    }
  }
}

// Typed version of our hits, should match returnFields (but camelCase)
export interface KomoDocumentNodeHit extends IQuestQueryHit {
  questId: string;

  questTitle: string;

  contents: string;
}